import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import checkImg from "../../assets/images/check_circle.svg";
import { LoginValue } from "../../Actions/LoginPageAction";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../../Actions/progressActions";
import { resetRememberDeviceState } from "../../reducers/RememberDeviceReducer";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const BackupCodesDialog = ({ open, onClose, onCancel }) => {
  const [backupCodes, setBackupCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const dispatch = useDispatch();
  const rememberDeviceCheck = useSelector(
    (state) => state.rememberDevice.rememberDeviceCheck
  );

  useEffect(() => {
    if (!showSuccessDialog) {
      dispatch(setProgress(60));
    } else {
      dispatch(setProgress(100));
    }
  }, [dispatch, showSuccessDialog]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    const userGuid = decodedToken.userGuid;

    const fetchBackupCodes = async () => {
      setLoading(true);
      setError("");
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/generateBackupCode`,
          { userGuid },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 409) {
          console.warn("Backup codes already exist. Fetching existing codes.");
        } else {
          console.error("Error generating backup codes:", err);
          setError(
            "An error occurred while generating backup codes. Please try again."
          );
          setLoading(false);
          return; // Exit the function if another error occurred
        }
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/listOfCodeViaUserGuid`,
          { userGuid },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const codes = response.data.codes?.map((item) => item.code) || [];
        setBackupCodes(codes);
      } catch (err) {
        console.error("Error fetching backup codes:", err);
        setError(
          "An error occurred while generating and fetching backup codes. Please try again."
        );
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchBackupCodes();
    }
  }, [open]);

  const getDeviceId = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const deviceId = result.visitorId;
    return deviceId;
  };

  const onNext = async () => {
    if (rememberDeviceCheck) {
      try {
        const deviceId = await getDeviceId();

        const token = JSON.parse(localStorage.getItem("token"));
        const decodedToken = jwtDecode(token);
        const userGuid = decodedToken.userGuid;

        const requestBody = {
          rememberDeviceCheck,
          deviceId,
          userGuid,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/addRememberDevice`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          console.error("Failed to remember device.");
        }
      } catch (error) {
        console.error("Error fetching deviceId or calling API:", error);
      }
    }
    dispatch(setProgress(100));
    setShowSuccessDialog(true);
  };

  const handleDone = () => {
    localStorage.setItem("isMFACompleted", "true");
    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("lastPath", "/floors");
    dispatch(LoginValue(true));
    dispatch(resetRememberDeviceState());
  };

  return (
    <>
      {!showSuccessDialog ? (
        <>
          <div className="backup-content">
            {loading ? (
              <p>Loading backup codes...</p>
            ) : error ? (
              <p style={{ color: "red" }}>{error}</p>
            ) : backupCodes.length > 0 ? (
              <div>
                <p
                  style={{
                    fontFamily: "Barlow Semi Condensed",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "23px",
                    marginBottom: "30px",
                  }}
                >
                  Keep this backup code somewhere safe but accessible:
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    width: "243px",
                    height: "183px",
                    justifySelf: "center",
                  }}
                >
                  {backupCodes.map((code, index) => (
                    <div
                      key={index}
                      style={{
                        textAlign: "center",
                        fontFamily: "Barlow Semi Condensed",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "23px",
                      }}
                    >
                      {code}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>
                No backup codes available. Please try generating them again.
              </p>
            )}
          </div>
          <div className="mfa-save">
            <Button variant="outlined" className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="disabled-button"
              disabled={loading || error}
              onClick={onNext}
            >
              Next
            </Button>
          </div>
        </>
      ) : (
        <div className="succes_modal">
          <img src={checkImg} alt="Success" />
          <h2>Success</h2>
          <p>Your verification is successfully completed.</p>
          <div className="mfa-save">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDone}
              className="done-btn"
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default BackupCodesDialog;
