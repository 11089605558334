import React, { useState, useEffect } from "react";
import { Box, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "../../../assets/images/DeleteIcon.svg";
// import addNew from '../../assets/images/addNew.svg';
import AddAudio from "./AddAudio.js";
import DeleteModal from "./DeleteModal";
import { useNavigate } from "react-router-dom";
import {baseUrl} from '../../../config/apiConfig'
import axios from "axios";
import "../GlobalSetting.scss";

const FloorManagement = () => {
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("Delete Successfully.");
  const [rows, setRows] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [reloadAudioList, setReloadAudioList] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null); // To track the selected row
  const navigate = useNavigate();

  const [checkboxState, setCheckboxState] = useState({
    default: [],
    drillStart: [],
    drillEnd: [],
    duringDrill: [],
  });

  const columns = [
    {
      field: "audioName",
      headerName: "Audio Name",
      flex: 1,
      sortable: true,
      disableColumnMenu: false,
    },
    {
      field: "audiofile",
      headerName: "Audio File",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <a href={params.value} className="blurPrintLink">
            Download
          </a>
        </div>
      ),
    },
    {
      field: "defaultAudio",
      headerName: "Default",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "centeredCell",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <input
            type="checkbox"
            checked={checkboxState.default.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params.row.id, "default")}
          />
        </div>
      ),
    },
    {
      field: "drillStart",
      headerName: "Drill Start Audio",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "centeredCell",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <input
            type="checkbox"
            checked={checkboxState.drillStart.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params.row.id, "drillStart")}
          />
        </div>
      ),
    },
    {
      field: "drillEnd",
      headerName: "Drill End Audio",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "centeredCell",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <input
            type="checkbox"
            checked={checkboxState.drillEnd.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params.row.id, "drillEnd")}
          />
        </div>
      ),
    },
    {
      field: "duringDrill",
      headerName: "During Drill",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "centeredCell",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <input
            type="checkbox"
            checked={checkboxState.duringDrill.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params.row.id, "duringDrill")}
          />
        </div>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      align: "right",
      headerAlign: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <img
            src={DeleteIcon}
            alt="delete"
            style={{
              width: "32px",
              height: "32px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={() => {
              setOpenDeleteModal(true);
              setDeviceIdToDelete(params.row.id);
            }}
          />
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (id, type) => {
    setCheckboxState((prev) => {
      // Create a new state object
      const newState = {
        ...prev,
        [type]: prev[type].includes(id) ? [] : [id], // Allow only one selection per category
      };

      // Call update API after setting the state
      updateSelectedAudio(newState);
      return newState;
    });
  };

  const processedRows = Array.isArray(rows)
    ? rows.map((row, index) => ({
        id: row.id,
        audioName: row.title || "N/A",
        audiofile: row.filepath || "N/A",
      }))
    : [];

  const fetchData = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("token"));
      const url = `${process.env.REACT_APP_API_URL}/getAllAlarmMedia`;
      const response = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log(response);

        // Set rows data
        setRows(response.data);

        // Initialize checkbox state
        const defaultSelectedIds = [];
        const drillStartSelectedIds = [];
        const drillEndSelectedIds = [];
        const duringDrillSelectedIds = [];

        // Loop through the response data and set the selected IDs based on the audio type
        response.data.forEach((row) => {
          if (row.default) defaultSelectedIds.push(row.id);
          if (row.drillStart) drillStartSelectedIds.push(row.id);
          if (row.drillEnd) drillEndSelectedIds.push(row.id);
          if (row.duringDrill) duringDrillSelectedIds.push(row.id);
        });

        // Set the checkbox state with the selected IDs for each audio type
        setCheckboxState({
          default: defaultSelectedIds,
          drillStart: drillStartSelectedIds,
          drillEnd: drillEndSelectedIds,
          duringDrill: duringDrillSelectedIds,
        });

        // If there is a default row, set it as the selected row
        const defaultRow = response.data.find((row) => row.default === true);
        if (defaultRow) {
          setSelectedRowId(defaultRow.id);
        }
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchData();
    };
    initialize();
  }, []);

  useEffect(() => {
    if (reloadAudioList) {
      fetchData();
    }
  }, [reloadAudioList]);

  const updateSelectedAudio = async (state, changedType, previousId) => {
    try {
      let token = JSON.parse(localStorage.getItem("token"));
      const url = `${process.env.REACT_APP_API_URL}/setMediaAlarm/defaultActive`;

      const requestBody = {
        primaryAudioId:
          state.default[0] ||
          (changedType === "default" ? null : checkboxState.default[0] || null),
        drillStartId:
          state.drillStart[0] ||
          (changedType === "drillStart"
            ? null
            : checkboxState.drillStart[0] || null),
        drillEndId:
          state.drillEnd[0] ||
          (changedType === "drillEnd"
            ? null
            : checkboxState.drillEnd[0] || null),
        duringDrillId:
          state.duringDrill[0] ||
          (changedType === "duringDrill"
            ? null
            : checkboxState.duringDrill[0] || null),
      };

      // Ensure previous selection is explicitly set to null if a new selection is made
      if (previousId) {
        requestBody[`${changedType}Id`] = state[changedType][0] || null;
      }

      const response = await axios.put(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log("Selected audio updated successfully:", response.data);
      } else {
        console.error("Failed to update selected audio:", response.data);
      }
    } catch (error) {
      console.error("Error updating selected audio:", error);
    }
  };

  const handleDelete = async (id) => {
    let token = JSON.parse(localStorage.getItem("token"));
    const url = `${process.env.REACT_APP_API_URL}/deleteAlarmMediaById/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        fetchData();
        setOpenToast(true);
      } else {
        console.error("Failed to delete device:", response.data);
      }
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };

  const handleToastClose = () => {
    setOpenToast(false);
    setMessage("");
  };

  return (
    <>
      <div className="userMangement_wrapper">
        <div className="AddUser">
          <button className="addDevice" onClick={() => setOpen(true)}>
            Add Audio
          </button>
        </div>
        <div className="inner_Wrapper">
          <div className="floorMangementTable_wrapper">
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={processedRows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          </div>
        </div>
      </div>
      <AddAudio
        open={open}
        setOpen={setOpen}
        setReloadAudioList={setReloadAudioList}
      />
      <DeleteModal
        setOpenDeleteModal={setOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        handleDelete={() => handleDelete(deviceIdToDelete)}
      />
      <Snackbar
        open={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={5000}
        message={message}
        onClose={handleToastClose}
        className={"success"}
      />
    </>
  );
};

export default FloorManagement;
