import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import HealthCard from './HealthCard';
import DetailedViewHealthCard from './DetailedViewHealthCard';
import arrowdown from '../../assets/images/arrow-down.svg';
import { fetchJavaData, fetchCameraData, fetchDetailedViewData, clearDetailedViewAc } from '../../Actions/HealthStatusAction'
import mqtt from "mqtt";
import './HealthStatus.scss'

const HealthStatus = () => {
    const [mqttStatus, setMqttStatus] = useState('critical')
    const [additonalText, setAdditonalText] = useState('')
    const [detailview, setDetailview] = useState(0)
    const [refreshKey, setRefreshKey] = useState(0)
    const [deviceHealth, setDeviceHealth] = useState({});
    const mqttClient = useRef(null);
    const dispatch = useDispatch();
    const [loadingDevices, setLoadingDevices] = useState(true);
    const { javaServerStatus, cameraStatus, cameraData, detailedViewData, loadingCamera, loadingJava, loading, error } = useSelector((state) => state.HealthStatusReducer);
   
    const handleConnect = useCallback(() => {
        window.mqttConnected = true;
        setMqttStatus('healthy');
        console.log('MQTT Connected');
    }, []);

    const handleError = useCallback((err) => {
        window.mqttConnected = false;
        setMqttStatus('critical');
        console.error('MQTT Connection Error:', err);
    }, []);

    const handleClose = useCallback(() => {
        window.mqttConnected = false;
        setMqttStatus('critical');
        console.log('MQTT Connection Closed');
    }, []);

    const handleReconnect = useCallback(() => {
        console.log('MQTT Reconnecting...');
        setMqttStatus('critical');
    }, []);
    useEffect(() =>{
        dispatch(fetchJavaData())
        dispatch(fetchCameraData()) 
    },[refreshKey])
    useEffect(() => {
        mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
            username: process.env.REACT_APP_MQTT_USERNAME,
            password: process.env.REACT_APP_MQTT_PASSWORD,
        });

        mqttClient.current.on('connect', handleConnect);
        mqttClient.current.on('error', handleError);
        mqttClient.current.on('close', handleClose);
        mqttClient.current.on('reconnect', handleReconnect);

        return () => {
            if (mqttClient.current) {
                mqttClient.current.end();
            }
        };
    }, [handleConnect, handleError, handleClose, handleReconnect, refreshKey]);
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshKey(prev => prev + 1); 
        }, 60000); 

        return () => clearInterval(interval);
    }, []);

    const clearDetailedView = () => {
        dispatch(clearDetailedViewAc()); 
        setDetailview(0);
        setAdditonalText('');
    }
    useEffect(() => {
        if (detailview !== 0) {
            dispatch(fetchDetailedViewData(detailview));
        }
    }, [detailview]);

    useEffect(() => {
        let topicName = process.env.REACT_APP_HEALTH_STATUS;
        mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
            username: process.env.REACT_APP_MQTT_USERNAME,
            password: process.env.REACT_APP_MQTT_PASSWORD,
        });
    
        mqttClient.current.on("connect", function () {
            window.mqttConnected = true;
            console.log("Connected to MQTT broker");
            mqttClient.current.subscribe(topicName);
        });
    
        mqttClient.current.on("message", function (topic, message) {
            const decodedMessage = JSON.parse(new TextDecoder("utf-8").decode(message));
            console.log("Decoded MQTT Message:", decodedMessage);
            
            const healthData = {};
            Object.entries(decodedMessage).forEach(([key, value]) => {
                healthData[key] = value.healthy ? 'healthy' : 'critical';
            });
    
            setDeviceHealth(healthData);
            setLoadingDevices(false);
        });
    }, []);
    
    return (
        <>
            <div className="healthStatusWrapper">
                <div className="main_heading">
                    {detailview !== 0 && <button className="arrowHandlerBtn" onClick={() => clearDetailedView()}><img src={arrowdown} /></button>}
                    <h1>{detailview == 1 ? "MQTT" : detailview == 2 ? "Java server" : detailview == 3 ? "Camera" : "System Health Status"}</h1>
                </div>
                {detailview == 0 ?
                    <div className="inner_Wrapper healthStatus_innerwrap">
                        <div className="statusColorWrapper">
                            <ul>
                                <li><span className="healthy"></span>Healthy</li>
                                <li><span className="warning"></span>Warning</li>
                                <li><span className="critical"></span>Critical</li>
                            </ul>
                        </div>
                        <div className="healthCard_wrapper">
                            <Row>
                                <HealthCard id="1" status={mqttStatus} cardHeading={"MQTT"} setDetailview={setDetailview} clickable={true} />
                                <HealthCard id="2" status={loadingJava ? "loading" : javaServerStatus} cardHeading={loadingJava ? "Loading..." : "Java server"}  setDetailview={setDetailview}  clickable={true}/>
                                <HealthCard id="3" status={loadingCamera ? "loading" : cameraStatus} cardHeading={loadingCamera ? "Loading..." : "Camera"} setDetailview={setDetailview} setAdditonalText={setAdditonalText} detailedViewCards={detailedViewData} clickable={!loadingCamera}  
                                />
                                {loadingDevices
            ? [...Array(4)].map((_, index) => (
                <HealthCard key={`loading-${index}`} status="loading" cardHeading={"Loading..."} clickable={false} />
              ))
            : Object.entries(deviceHealth).map(([device, status], index) => (
                <HealthCard 
                    key={index + 4}  
                    id={index + 4} 
                    status={status} 
                    cardHeading={device} 
                    setDetailview={setDetailview} 
                    clickable={false} 
                />
            ))}
                            </Row>
                        </div>
                    </div>
                    : <div className="inner_Wrapper">
                        <DetailedViewHealthCard detailedViewCards={additonalText == "camera" ?  cameraData :  detailedViewData} additonalText={additonalText} />
                    </div>
                }
            </div>

        </>
    );
};

export default HealthStatus;
