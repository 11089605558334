import React from 'react';
import './style.scss'
import {Container, Row, Col} from 'react-bootstrap';
import SigninPage from "./SigninPage";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import {useLocation, useNavigate} from "react-router-dom";
import MFADialog from './MFADialog';
import { Link } from "react-router-dom";

const LoginPage = () =>{
    const location = useLocation();
    const isForgotPassword = location.pathname.includes("/ForgotPassword");
    const isResetPassword = location.pathname.includes("/resetPassword");
    const isMFAOptions = location.pathname === "/mfa-options";
    const navigate = useNavigate();

    const handleCloseMFADialog = () => {
        navigate('/');
    };
    
    const renderForm = () => {
        return isForgotPassword ? <ForgotPassword /> : isResetPassword ? <ResetPassword /> : <SigninPage />;
      };
    
      
    return(
        <>
        <div className={`Login_container ${isForgotPassword ? "forgetPage" : "mainLoginPage"}`}>
            <Container>
                <div className="header">
                   <Row>
                   <Col xl={6}><Link to="/" className="logoImg"></Link></Col>
                   <Col xl={6}>
                        <div className="needAndsupport">
                        <Link to="/needAssistanceandsupport" className="needsupportAnchor">Need Assistance? Help & Support</Link>
                        </div>
                   </Col>
                   </Row>
                </div>
                <div className="middleContainer">
                    <Row>
                        <Col xl={7}>
                            <div class="left-section">
                                <div class="branding">
                                    <p class="tagline">Developed by experts. <span class="highlight">Powered by AI.</span></p>
                                    <p class="description">Reduce the risk of gun violence incidents. Secure people in public buildings and spaces with proven, patented technologies and training solutions developed by experts and fueled by AI Threat Intelligence.</p>
                                    <a href="https://www.lockdownexperts.com/" target='new' class="know-more-btn">Know Now</a>
                                </div>
                            </div>
                        </Col>
                        <Col xl={5}>
                            <div className="right_wrapper">
                                {renderForm()}
                            </div>
                        </Col> 
                    </Row>
                </div>
            </Container>
        </div>
        <footer>
            <Container>
            <Row>
                <Col xl={6}><p className="left">Lockdown. Save lives.</p></Col>
                <Col xl={6}><p className="right">&copy; 2024 Lockdown Experts, LLC.All rights reserved.</p></Col>
            </Row>
        </Container>
        </footer>
        {isMFAOptions && <MFADialog open={true} onClose={handleCloseMFADialog} />}
    </>
    )
}

export default LoginPage