const initialState = {
    rememberDeviceCheck: false,
  };
  
  const RememberDeviceReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_REMEMBER_DEVICE_CHECK":
        return {
          ...state,
          rememberDeviceCheck: action.payload,
        };
      case "RESET_REMEMBER_DEVICE_STATE":
        return {
          ...state,
          rememberDeviceCheck: false,
        };
      default:
        return state;
    }
  };
  
  export const setRememberDeviceCheck = (payload) => ({
    type: "SET_REMEMBER_DEVICE_CHECK",
    payload,
  });
  
  export const resetRememberDeviceState = () => ({
    type: "RESET_REMEMBER_DEVICE_STATE",
  });
  
  export default RememberDeviceReducer;
  