import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { jwtDecode } from "jwt-decode";
import BackupCodesDialog from "./BackupCodesDialog";
import "./MFADialog.scss";
import { setProgress } from "../../Actions/progressActions";
import { useDispatch, useSelector } from "react-redux";
import { resetRememberDeviceState } from '../../reducers/RememberDeviceReducer';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const AuthenticatorAppDialog = ({ barcode, isFirstLogin, onCancel }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showBackupCodesDialog, setShowBackupCodesDialog] = useState(false);
  const [callBackupCodes, setCallBackupCodes] = useState(false);
  const dispatch = useDispatch();
  const rememberDeviceCheck = useSelector((state) => state.rememberDevice.rememberDeviceCheck);

  useEffect(() => {
    dispatch(setProgress(60));
  }, [dispatch]);

  const getDeviceId = async () => {
    // Initialize the agent
    const fp = await FingerprintJS.load();
    // Get the visitor identifier
    const result = await fp.get();
    const deviceId = result.visitorId;
    console.log("Device ID:", deviceId);
    return deviceId;
  };

  const handleCancel = () => {
    setShowBackupCodesDialog(false);
    dispatch(resetRememberDeviceState());
  };

  const saveRememberDevice = async () => {
    if (!rememberDeviceCheck) return;
    
    try {
      const deviceId = await getDeviceId();
      
      // Extract userGuid from token
      const token = JSON.parse(localStorage.getItem("token"));
      const decodedToken = jwtDecode(token);
      const userGuid = decodedToken.userGuid;
      
      // Prepare payload
      const requestBody = {
        rememberDeviceCheck,
        deviceId,
        userGuid,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addRememberDevice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        console.error("Failed to remember device.");
      } else {
        console.log("Device remembered successfully.");
      }
    } catch (error) {
      console.error("Error fetching deviceId or calling API:", error);
    }
  };

  const handleVerification = async () => {
    if (!code || code.length !== 6) {
      setErrorMessage(
        !code
          ? "Please enter the verification code."
          : "Please enter a 6-digit code."
      );
      return;
    }

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify`,
        {
          email: decodedToken.email,
          code,
        }
      );

      if (response.data.verifyCode) {
        // Only save remember device if verification is successful
        if (rememberDeviceCheck) {
          await saveRememberDevice();
        }
        
        setShowBackupCodesDialog(true);
        setCallBackupCodes(true);
        //dispatch(setProgress(100));
      } else {
        setErrorMessage("Wrong verification code.");
      }
    } catch (error) {
      console.error("Failed to verify code:", error);
      setErrorMessage(
        "An error occurred during verification. Please try again."
      );
    }
  };

  return (
    <>
      {!showBackupCodesDialog ? (
        <div className="dialog-content emailDailog_wrapper barcodeScreen">
          <p>
            Scan this QR code below with your authentication app. It will
            generate a 6-digit code to verify your account.
          </p>
          <img
            src={barcode}
            alt="QR Code"
            style={{
              display: "block",
              margin: "0 auto",
              width: "211px",
              height: "216px",
              border: "1px solid #D9D9D9",
            }}
          />
          <div style={{ display: "inline-grid", marginTop: "30px" }}>
            <label htmlFor="verification-code">
              Enter the 6-Digit Verification Code
            </label>
            <input
              type="text"
              id="verification-code"
              placeholder="XXXXXX"
              maxLength="6"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setErrorMessage("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleVerification();
                }
              }}
              style={{
                padding: "10px",
                marginTop: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "274px",
              }}
            />
          </div>
          {errorMessage && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {errorMessage}
            </div>
          )}
          <div className="mfa-save">
            <Button 
              variant="outlined" 
              className="cancelBtn" 
              onClick={() => {
                dispatch(resetRememberDeviceState());
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleVerification} className="disabled-button">
              Next
            </Button>
          </div>
        </div>
      ) : (
        isFirstLogin && (
          <BackupCodesDialog 
            open={callBackupCodes} 
            onCancel={() => {
              dispatch(resetRememberDeviceState());
              handleCancel();
            }} 
          />
        )
      )}
    </>
  );
};

export default AuthenticatorAppDialog;
