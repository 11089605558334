import React from 'react';
import { CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import './HealthStatus.scss';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const DetailedViewHealthCard = ({ detailedViewCards, additonalText }) => {
  if (!detailedViewCards || (typeof detailedViewCards !== 'object' && !Array.isArray(detailedViewCards))) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}> <CircularProgress /></div>;
  }

  // Ensure data is an array
  const dataArray = Array.isArray(detailedViewCards) ? detailedViewCards : [detailedViewCards];

  if (dataArray.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  

  const displayFields = additonalText === 'camera' ? ['name', 'streamUrl', 'status'] : Object.keys(dataArray[0]);
  
  const columns = displayFields.map((key) => ({
    field: key,
    headerName: key === 'name' ? 'Camera Name' : key.replace(/([A-Z])/g, ' $1').split(' ').map(capitalize).join(' '),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (key === 'status') {
        return (
          <div className={params.value == "Active" ? 'status-green' : 'status-red'}>
            {params.value}
          </div>
        );
      }
      return params.value;
    },
  }));

  const rows = dataArray.map((item, index) => ({
    id: index + 1,
    name: item.name,
    streamUrl: item.streamUrl,
    ...item,
  }));

  return (
    <div className="detailedViewCards">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        disableRowSelectionOnClick
        pagination
        hideFooter
      />
    </div>
  );
};

export default DetailedViewHealthCard;
