import axios from "axios";
// Dashboard Data API
export const fetchJavaData = () => async (dispatch) => {
  let token = JSON.parse(localStorage.getItem("token"));
  dispatch({ type: "JAVA_DATA_REQUEST" });
  try {
    const url = `${process.env.REACT_APP_SERVER_API_URL}/app-status`;
    const response = await axios({
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: "JAVA_DATA_SUCCESS",
        payload: "healthy",
      });
    }
  } catch (error) {
    console.error("Error fetching java data:", error);
    dispatch({
      type: "JAVA_DATA_FAILURE",
      payload: "critical",
    });
  }
};
// Camera Data Api
export const fetchCameraData = () => async (dispatch) => {
  dispatch({ type: "FETCH_CAMERA_REQUEST" });
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    const url = `${process.env.REACT_APP_SERVER_API_URL}/camera/health`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: "FETCH_CAMERA_SUCCESS",
        payload: response.data,
      });

      const allHealthy = response.data.every(
        (camera) => camera.status === "Active"
      );
      dispatch({
        type: "SET_CAMERA_STATUS",
        payload: allHealthy ? "healthy" : "critical",
      });
    } else {
      dispatch({ type: "FETCH_CAMERA_FAIL" });
      dispatch({ type: "SET_CAMERA_STATUS", payload: "critical" });
    }
  } catch (error) {
    dispatch({ type: "FETCH_CAMERA_FAIL" });
    dispatch({ type: "SET_CAMERA_STATUS", payload: "critical" });
  }
};

// Detailed view
export const fetchDetailedViewData = (detailview) => async (dispatch) => {
  dispatch({ type: "FETCH_DETAILED_VIEW_REQUEST" });

  const apis = {
    1: `${process.env.REACT_APP_SERVER_API_URL}/v1/mqttStatus`,
    2: `${process.env.REACT_APP_SERVER_API_URL}/getCpu&MemoryUsage`,
  };

  const url = apis[detailview];

  if (url && detailview !== 0) {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch({
          type: "FETCH_DETAILED_VIEW_SUCCESS",
          payload: response.data,
        });
      } else {
        dispatch({
          type: "FETCH_DETAILED_VIEW_FAILURE",
          payload: `Unexpected status code: ${response.status}`,
        });
      }
    } catch (error) {
      dispatch({
        type: "FETCH_DETAILED_VIEW_FAILURE",
        payload: error.message,
      });
    }
  }
};
// Clear Function
export const clearDetailedViewAc = () => ({
  type: "CLEAR_DETAILED_VIEW",
});
