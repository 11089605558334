import {combineReducers} from 'redux'
import FloormapReducer from './FloormapReducer'
import LogInReducer from './LogInReducer'
import SnackbarReducer from "./snackbarSlice";
import ProgressReducer from './ProgressReducer';
import RememberDeviceReducer from './RememberDeviceReducer';
import HealthStatusReducer from './HealthStatusReducer';
import AddEditUserReducer from './AddEditUserReducer';
import UserReducer from './UserReducer';
import audioReducer from './audioSlice';
const rootReducer = combineReducers({
    snackbar : SnackbarReducer,
    floormap : FloormapReducer,
    login: LogInReducer,
    progress: ProgressReducer,
    rememberDevice: RememberDeviceReducer,
    HealthStatusReducer: HealthStatusReducer,
    usersState: AddEditUserReducer,
    userList: UserReducer,
    audio: audioReducer,
})

export default rootReducer;