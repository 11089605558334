import axios from "axios";

export const AddUserAction = (formDataToSend) => async (dispatch) => {
    let token = JSON.parse(localStorage.getItem("token"));
    dispatch({ type: "Add_USER_REQUEST_LOADING" });
    try {
      const url = `${process.env.REACT_APP_API_URL}/users`;
      const response = await axios({
        method: 'POST',
        url: url,
        data: formDataToSend,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
      if (response.status === 200) {
        dispatch({
          type: "Add_USER_REQUEST_SUCCESS",
          payload: response.data,
        });
        dispatch(ListUserAction());
      }
    } catch (error) {
      dispatch({
        type: "Add_USER_REQUEST_ERROR",
        payload: error.response,
      });
    }
  };

  export const EditUserAction = (formDataToSend, id) => async (dispatch) => {
    let token = JSON.parse(localStorage.getItem("token"));
    dispatch({ type: "EDIT_USER_REQUEST_LOADING" });
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${id}`;
      const response = await axios({
        method: 'PUT',
        url: url,
        data: formDataToSend,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
      if (response.status === 200) {
        dispatch({
          type: "EDIT_USER_REQUEST_SUCCESS",
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: "EDIT_USER_REQUEST_ERROR",
        payload: error.response,
      });
    }
  };

  export const DeleteUserAction = (id) => async (dispatch) => {
    let token = JSON.parse(localStorage.getItem("token"));
    dispatch({ type: "DELETE_USER_LOADING" });
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${id}`;
      const response = await axios({
          method: 'DELETE',
          url: url,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }
      });
      if (response.status === 200) {
        dispatch({ type: "DELETE_USER_SUCCESS" })
        dispatch(ListUserAction());
      }
    } catch (error) {
      dispatch({
        type: "DELETE_USER_ERROR",
        payload: error.response,
      });
    }
  };

  export const ListUserAction = () => async (dispatch) => {
    let token = JSON.parse(localStorage.getItem("token"));
    dispatch({ type: "LIST_USER_LOADING" });
    try {
      const url = `${process.env.REACT_APP_API_URL}/getUserList`
      const response = await axios({
          method: 'GET',
          url: url,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }
      });
      if (response.status === 200) {
        dispatch({
          type: "LIST_USER_SUCCESS",
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: "LIST_USER_ERROR",
        payload: error.response,
      });
    }
  };

  export const DeleteReset = ()  => async (dispatch) => dispatch({
    type: "DELETE_USER_RESET",
  });