import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import MyProfile from './MyProfile/MyProfile'
import UpdatePassword from './UpdatePassword/UpdatePassword'
import Preference from './Preference/Preference'
import './profile.scss'

const Profile = () => {
    const [activeScreen, setActiveScreen] = useState('profile')
    return (
        <>
            <div className="profileWrapper">
                <div className="main_heading">
                    <h1>My Account</h1>
                </div>
                <div className="menu_bar">
                    <ul>
                        <li>
                            <button
                                className={activeScreen === 'profile' ? 'active' : ''}
                                onClick={() => setActiveScreen('profile')}
                            >
                                Profile
                </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'password' ? 'active' : ''}
                                onClick={() => setActiveScreen('password')}
                            >
                                Security
                </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'preference' ? 'active' : ''}
                                onClick={() => setActiveScreen('preference')}
                            >
                                Notification &#38; Preferences
                </button>
                        </li>
                    </ul>
                </div>

                <div className={`userPassdDetail_wrapper ${activeScreen == "profile" ? "myprofileMedia" : activeScreen == "password" ? "mypasswordMedia" : "myprefrenceMedia"}`}>
                    {activeScreen == "profile" ? <MyProfile /> : activeScreen == "password" ? <UpdatePassword /> : <Preference />}
                </div>
                {/* <UpdateProfilePhoto
                    open={open}
                    setOpen={setOpen}
                    fetchImage={fetchImage}
                /> */}
            </div>

        </>
    );
};

export default Profile;
