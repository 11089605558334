class AudioService {
  constructor() {
    this.audio = new Audio();
    this.isPlaying = false;
    
    this.currentPlaybackStartTime = 0;
    this.playbackDuration = 0;
    
    this.audio.onended = () => {
      this.isPlaying = false;
      this.currentPlaybackStartTime = 0;
      
      if (this.onEndedCallback) {
        this.onEndedCallback();
      }
    };
    
    this.audio.onerror = (error) => {
      console.error("Audio playback error:", error);
      this.isPlaying = false;
    };
  }
  
  setOnEndedCallback(callback) {
    this.onEndedCallback = callback;
  }

  playAudio(audioSrc) {
    if (!audioSrc) {
      console.warn("No audio source provided");
      return Promise.reject("No audio source provided");
    }
    
    if (this.isPlaying && this.audio.src === audioSrc) {
      return Promise.resolve();
    }
    
    if (this.isPlaying) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    
    return new Promise((resolve, reject) => {
      this.audio.src = audioSrc;
      
      const loadHandler = () => {
        this.playbackDuration = this.audio.duration * 1000; // in milliseconds
        
        // Play the audio
        this.audio.play()
          .then(() => {
            this.isPlaying = true;
            this.currentPlaybackStartTime = Date.now();
            resolve();
          })
          .catch((err) => {
            console.error("Error playing audio:", err);
            this.isPlaying = false;
            reject(err);
          });
          
        this.audio.removeEventListener('loadedmetadata', loadHandler);
      };
      
      this.audio.addEventListener('loadedmetadata', loadHandler);
      
      this.audio.load();
    });
  }

  stopAudio() {
    if (!this.isPlaying) return;
    
    this.audio.pause();
    this.audio.currentTime = 0;
    this.isPlaying = false;
    this.currentPlaybackStartTime = 0;
  }
  
  isAudioStillPlaying() {
    if (this.currentPlaybackStartTime > 0 && this.playbackDuration > 0) {
      const elapsedTime = Date.now() - this.currentPlaybackStartTime;
      return elapsedTime < this.playbackDuration;
    }
    
    return this.isPlaying;
  }
  
  getRemainingPlaybackTime() {
    if (!this.isPlaying || this.currentPlaybackStartTime === 0) return 0;
    
    const elapsedTime = Date.now() - this.currentPlaybackStartTime;
    const remaining = this.playbackDuration - elapsedTime;
    
    return Math.max(0, remaining);
  }
}

const audioService = new AudioService();
export default audioService;