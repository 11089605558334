import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import BackupLogin from "./BackupLogin";
import "./MFALogin.css";
import { jwtDecode } from "jwt-decode";
import checkImg from "../../assets/images/check_circle.svg";
import { LoginValue } from "../../Actions/LoginPageAction";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../../Actions/progressActions";
import { resetRememberDeviceState } from "../../reducers/RememberDeviceReducer";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const MFALogin = ({ open, onClose, onCancel }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [enableNextBtn, setEnableNextBtn] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [showBackupLogin, setShowBackupLogin] = useState(false);
  const verifyButtonRef = useRef(null);
  const dispatch = useDispatch();
  const rememberDeviceCheck = useSelector(
    (state) => state.rememberDevice.rememberDeviceCheck
  );

  useEffect(() => {
    if (!successDialogOpen) {
      dispatch(setProgress(60));
    } else {
      dispatch(setProgress(100));
    }
  }, [successDialogOpen, dispatch]);

  useEffect(() => {
    if (enableNextBtn) {
      console.log("Dispatching progress update to 100");
      dispatch(setProgress(100));
    }
  }, [enableNextBtn, dispatch]);

  const getDeviceId = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const deviceId = result.visitorId;
    return deviceId;
  };

  const handleDone = () => {
    // Mark MFA as completed
    localStorage.setItem("isMFACompleted", "true");
    // Mark user as authenticated
    localStorage.setItem("isAuthenticated", "true");
    // Save the default path
    localStorage.setItem("lastPath", "/floors");
    // Dispatch Redux action and navigate
    dispatch(LoginValue(true));
    dispatch(resetRememberDeviceState());
    // navigate("/dashboard", { replace: true });
  };

  const handleCodeVerification = async () => {
    if (!code || code.length !== 6) {
      setErrorMessage(
        !code
          ? "Please enter the verification code."
          : "Please enter a 6-digit code."
      );
      return;
    }

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: decodedToken.email,
            code,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.verifyCode === true) {
        setMessage("Verification successful!");
        setErrorMessage("");
        setEnableNextBtn(true);
      } else {
        setErrorMessage("Verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleNext = async () => {
    if (rememberDeviceCheck) {
      try {
        const deviceId = await getDeviceId();

        const token = JSON.parse(localStorage.getItem("token"));
        const decodedToken = jwtDecode(token);
        const userGuid = decodedToken.userGuid;

        const requestBody = {
          rememberDeviceCheck,
          deviceId,
          userGuid,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/addRememberDevice`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          console.error("Failed to remember device.");
        }
      } catch (error) {
        console.error("Error fetching deviceId or calling API:", error);
      }
    }
    
    dispatch(setProgress(100));
    setSuccessDialogOpen(true);
  };

  const handleBackupCodeClick = () => {
    setShowBackupLogin(true);
  };

  const handleCancelBackup = () => {
    setShowBackupLogin(false); // Return to MFALogin
  };

  return (
    <>
      {!successDialogOpen ? (
        <>
          {showBackupLogin ? (
            <BackupLogin
              onClose={() => setShowBackupLogin(false)}
              open={showBackupLogin}
              onCancel={handleCancelBackup}
            />
          ) : (
            <>
              <div className="dialog-content emailDailog_wrapper">
                <p>
                  Please enter the verification code displayed on your
                  authenticator app.
                </p>
                <div className="authenticator-input">
                  <div className="label-text">Authenticator App</div>
                  <div className="align">
                    <input
                      type="text"
                      className="text-box"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                        setMessage("");
                        setErrorMessage("");
                      }}
                      maxLength="6"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          verifyButtonRef.current.focus();
                          handleCodeVerification();
                        }
                      }}
                    />
                    <div className="send-code-button">
                      <Button
                        color="primary"
                        className="btn-text"
                        ref={verifyButtonRef} // Attach the ref to the button
                        onClick={handleCodeVerification}
                      >
                        Verify
                      </Button>
                    </div>
                  </div>
                  <div onClick={handleBackupCodeClick} className="loginViacode">
                    Login via backup code
                  </div>
                  {message && (
                    <div style={{ color: "green", marginTop: "20px" }}>
                      {message}
                    </div>
                  )}
                  {errorMessage && (
                    <div style={{ color: "red", marginTop: "20px" }}>
                      {errorMessage}
                    </div>
                  )}
                </div>
              </div>

              <div className="mfa-save">
                <Button
                  variant="outlined"
                  className="cancelBtn"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="disabled-button"
                  disabled={!enableNextBtn}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="succes_modal">
          <img src={checkImg} alt="Success" />
          <h2>Success</h2>
          <p>Your verification is successfully completed.</p>
          <div className="mfa-save">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDone}
              className="done-btn"
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MFALogin;
