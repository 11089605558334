import React from 'react';
import './style.scss'
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from "react-router-dom";

const NeedAssistance = () =>{
    return(
        <>
        <div className="needasst_wrapper">
            <Container fluid>
            <Row>
                <div className="header needAsst_header">
                 
                   <Col xl={6}><Link to="/" className="logoImg"></Link></Col>
                </div>
                   </Row>
                <div className="middleContainer">
                    <Row>
                        <Col xl={12}>
                        <div class="left-section">
                                <h1>Connect With Us</h1>
                                <div className='child_wrapper'>
                                    <h3>Phone Number:</h3>
                                    <a href="tel:+17032013591">+1 (703) 201-3591</a>
                                </div>

                                <div className='child_wrapper'>
                                    <h3>Email ID:</h3>
                                    <a href="mailto:tim.heaps@lockdownexperts.com">tim.heaps@lockdownexperts.com</a>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
        <footer className='assst_footer'>
            <Container fluid>
            <Row>
                <Col xl={6}><p className="left">Lockdown. Save lives.</p></Col>
                <Col xl={6}><p className="right footer_right_asst">&copy; 2024 Lockdown Experts, LLC.All rights reserved.</p></Col>
            </Row>
        </Container>
        </footer>
      
    </>
    )
}

export default NeedAssistance