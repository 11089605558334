const initialState = {
    data: null,
    isLoading: false,
    error: '',
  };
  const AddEditUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case "Add_USER_REQUEST_LOADING":
        return {
          ...state,
          isLoading: true,
          data: null,
          error: '',
        };
      case "Add_USER_REQUEST_SUCCESS":
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          error: '',
        };
      case "Add_USER_REQUEST_ERROR":
        return {
          ...state,
          isLoading: false,
          data: null,
          error: action.payload,
        };
      case "EDIT_USER_REQUEST_LOADING":
        return {
          ...state,
          isLoading: true,
          data: null,
          error: '',
        };
      case "EDIT_USER_REQUEST_SUCCESS":
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          error: '',
        };
      case "EDIT_USER_REQUEST_ERROR":
        return {
          ...state,
          isLoading: false,
          data: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default AddEditUserReducer;
  