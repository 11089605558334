import React, { useState, useEffect } from "react";
import {
  Box,
  styled,
  Switch,
  Stack,
  FormGroup,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import errorSign from "../../../assets/images/errorSign.svg";
import checkImg from "../../../assets/images/check_circle.svg";
import { fieldUser, prefrenceData } from "./FieldJson";
import { jwtDecode } from "jwt-decode";
import "../profile.scss";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: "flex",
  borderRadius: 16,
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(8, 109, 171, 1)",
        ...theme.applyStyles("dark", {
          backgroundColor: "rgba(8, 109, 171, 1)",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 16,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));
const Profile = () => {
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationChannel, setNotificationChannel] = useState([
    // only keeping by default option Email for now
    { notificationName: "Email", isActive: false },
    // { notificationName: 'SMS', isActive: false },
    // { notificationName: 'Push Notification', isActive: false },
  ]);

  const handleToggle = (index) => {
    setNotificationChannel((prevChannels) =>
      prevChannels.map((channel, i) =>
        i === index ? { ...channel, isActive: !channel.isActive } : channel
      )
    );
  };

  // Function for hsndling Language and Time Zone dropdown change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch initial notification settings
  const fetchNotificationSettings = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getProfileDetails?userGuid=${decodedToken.userGuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setNotificationChannel(response.data.notificationChannel);

        const preferenceData = {};
        const fieldMapping = {
          preferedLang: "preferedLang",
          timezone: "timezone",
        };

        Object.keys(fieldMapping).forEach((formField) => {
          const apiField = fieldMapping[formField];
          if (
            response.data[apiField] !== null &&
            response.data[apiField] !== undefined
          ) {
            preferenceData[formField] = response.data[apiField];
          }
        });

        setFormData(preferenceData);
      }
    } catch (error) {
      console.error("Error fetching notification settings:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const formDataToSend = {
        notificationChannel,
        ...formData,
      };
      const url = `${process.env.REACT_APP_API_URL}/updateProfile?userGuid=${decodedToken.userGuid}`;
      const response = await axios.put(url, formDataToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setMessage("Notifications Updated");
        setOpen(true);
        await fetchNotificationSettings(); // Re-fetch data to update state
      }
    } catch (error) {
      console.error("Error making request:", error);
      setMessage("Error");
    }
  };

  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };

  const notificationDescriptions = {
    SMS: "Receive important notifications via text message",
    Email: "Receive personalized emails about account activites",
    "Push Notification": "Receive instant alerts directly to your device",
  };

  return (
    <div className="update_pass_mnWrapper">
      <div className="updatePassword_myacc">
        <div className="update_wrapper security_check_wrapper">
          <div className="profile-form">
            <h2>Notifications</h2>

            {notificationChannel
              ?.sort((a, b) => {
                const order = ["Email", "SMS", "Push Notification"];
                return (
                  order.indexOf(a.notificationName) -
                  order.indexOf(b.notificationName)
                );
              })
              ?.map((data, index) => (
                <div className="securityChecks" key={index}>
                  <div className="sc_heading">
                    <p>{data.notificationName}</p>
                    <span>
                      {notificationDescriptions[data.notificationName]}
                    </span>
                  </div>
                  <div className="sc_toggle">
                    <FormGroup>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: "center" }}
                      >
                        <AntSwitch
                          disabled={
                            data.notificationName === "Push Notification"
                          }
                          checked={data.isActive}
                          onChange={() => handleToggle(index)}
                          inputProps={{ "aria-label": "notification toggle" }}
                        />
                      </Stack>
                    </FormGroup>
                  </div>
                </div>
              ))}

            <div className="profile_wrapperbtn">
              <button
                type="submit"
                className="btnSubmit save"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={5000}
                message={message}
                onClose={handleClose}
                className={"success"}
              />
            </div>
          </div>
        </div>
        <div className="update_wrapper">
          <div className="profile-form dropdownWrapper">
            <h2>Preferences</h2>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              className="field"
            >
              {prefrenceData.map((data, index) => {
                return (
                  <FormControl fullWidth className="innerFields" key={index}>
                    <InputLabel
                      variant="standard"
                      htmlFor={data.id}
                      shrink={false}
                    >
                      {data.label}
                    </InputLabel>
                    <NativeSelect
                      id={data.id}
                      name={data.name}
                      value={formData[data.name] || ""}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select a {data.label}
                      </option>
                      {data.data &&
                        data.data.map((role) => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                    </NativeSelect>
                  </FormControl>
                );
              })}
            </Box>

            <div className="profile_wrapperbtn">
              <button
                type="submit"
                className="btnSubmit save"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
