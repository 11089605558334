import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import "./App.css";
import Header from "./Component/Header/Header";
import SideBar from "./Component/SideBar/SideBar";
import FloorMap from "./Component/FloorMap/FloorMap";
import LoginPage from "./Component/LoginPage/LoginPage";
import HealthStatus from "./Component/HealthStatus/HealthStatus";
import FloorManagement from "./Component/FloorManagement/FloorManagement";
import Devices from "./Component/Devices/Devices";
import DevicesComp from "./Component/DevicesComp/DevicesComp";
import CameraFeed from "./Component/CameraFeed/CameraFeed";
import Analytics from "./Component/Analytics/Analytics";
import NeedAssistance from "./Component/LoginPage/NeedAssistance";
import Profile from "./Component/Profile/Profile";
import MangeUser from "./Component/MangeUser/MangeUser";
import AddDevices from "./Component/FloorManagement/AddDevices";
import GlobalSetting from "./Component/GlobalSetting/GlobalSetting";
import SessionWarning from "./Component/SessionWarning/SessionWarning";
import ProtectedRoute from "./ProtectedRoute";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Alert, Snackbar } from "@mui/material";
import { toggleSnackbar } from "./reducers/snackbarSlice";

function App() {
  const [userDataStorage, setUserDataStorage] = useState("");
  const [isInactive, setIsInactive] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(180); // Timeout countdown in seconds
  const [modalActive, setModalActive] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(false);
  const newref = useRef(null);

  const loginDetail = useSelector((state) => state.login);

  const enableSnackbar = useSelector((state) => state.snackbar.enableSnackbar);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let inactivityTimeout;
  let countdownInterval;

  // useEffect(() => {
  //   const storedDrillMode = localStorage.getItem("drill_mode");

  //   if (storedDrillMode === "1" || storedDrillMode === "true") {
  //     dispatch(toggleSnackbar(true));
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   localStorage.setItem("drill_mode", enableSnackbar);
  // }, [enableSnackbar]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        //const token = JSON.parse(localStorage.getItem("token"));
        //const decodedToken = jwtDecode(token);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`);
        const fetchedSettings = {};

        response.data.allGlobalSettings.forEach((item) => {
          fetchedSettings[item.code] = item.value;
        });

        console.log(fetchedSettings["drill_mode"], "kkk");

        //const currentUser = decodedToken.userGuid;
        //const dMUser = fetchedSettings["drill_mode_user"];
        if (fetchedSettings["drill_mode"] === "1") {
          //console.log("dMUser:", dMUser, "currentUser:", currentUser);
          dispatch(toggleSnackbar(true));
        } else if (fetchedSettings["drill_mode"] === "0") {
          dispatch(toggleSnackbar(false));
        }

      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, [location.pathname]);

  const startInactivityTimer = () => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    if (isAuthenticated) {
      const currentTime = Date.now();
      localStorage.setItem("lastActivityTime", currentTime);
  
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        const lastActivityTime = parseInt(localStorage.getItem("lastActivityTime"), 10);
        const elapsedTime = Date.now() - lastActivityTime;
  
        if (elapsedTime >= 12 * 60 * 1000 && elapsedTime < 15 * 60 * 1000) {
          setIsInactive(true);
          setModalActive(true);
          startCountdown();
        } else if (elapsedTime >= 15 * 60 * 1000) {
          handleLogout();
        }
      }, 12 * 60 * 1000);
    }
  };
  

  const startCountdown = () => {
    clearInterval(countdownInterval);
    setTimeRemaining(180); // 3 minutes
    countdownInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdownInterval);
          handleLogout();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };
  

  const resetSession = () => {
    setIsInactive(false);
    setModalActive(false);
    clearInterval(countdownInterval);
    startInactivityTimer();
  };
  

  const handleLogout = () => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    if (isAuthenticated) {
      clearTimeout(inactivityTimeout);
      clearInterval(countdownInterval);
      setUserDataStorage("");
      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT" });
      navigate("/", { replace: true });
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("isMFACompleted");
      localStorage.removeItem("lastPath");
      setIsAuthentication(false);
      setIsInactive(false);
      setModalActive(false);
    }
  };

  useEffect(() => {
    const initializeApp = () => {
      const storedToken = JSON.parse(localStorage.getItem("token"));
      const lastPath = localStorage.getItem("lastPath");
      const lastActivityTime = parseInt(localStorage.getItem("lastActivityTime"), 10);
      const elapsedTime = Date.now() - lastActivityTime;
  
      if (storedToken) {
        setUserDataStorage(storedToken);
  
        if (!redirected) {
          setRedirected(true);
          navigate(lastPath || "/floors", { replace: true });
        }
  
        if (elapsedTime >= 15 * 60 * 1000) {
          // Logout if inactivity exceeds 15 minutes
          handleLogout();
        } else if (elapsedTime >= 12 * 60 * 1000) {
          // Show the dialog if within the countdown window
          setIsInactive(true);
          setModalActive(true);
          startCountdown();
        } else {
          startInactivityTimer();
        }
      } else {
        handleLogout();
      }
    };
  
    initializeApp();
  
    const resetInactivityTimer = () => {
      if (!modalActive) {
        const currentTime = Date.now();
        localStorage.setItem("lastActivityTime", currentTime);
        clearTimeout(inactivityTimeout);
        startInactivityTimer();
      }
    };
  
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    window.addEventListener("click", resetInactivityTimer);
  
    return () => {
      clearTimeout(inactivityTimeout);
      clearInterval(countdownInterval);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      window.removeEventListener("click", resetInactivityTimer);
    };
  }, [redirected, modalActive]);
  
  useEffect(() => {
    if (userDataStorage) {
      localStorage.setItem("lastPath", location.pathname);
    }
  }, [location.pathname, userDataStorage]);
  
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const isMFACompleted = localStorage.getItem("isMFACompleted") === "true";
    setIsAuthentication(isAuthenticated && isMFACompleted);
  }, [loginDetail]);
  
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          alert("Your session has expired or your account has been deleted.");
          handleLogout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const isDevicesPage = location.pathname.includes("/devices");

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        {!isAuthentication ? (
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/mfa-options" element={<LoginPage />} />
            <Route path="/ForgotPassword" element={<LoginPage />} />
            <Route path="/resetPassword" element={<LoginPage />} />
            <Route path="/needAssistanceandsupport" element={<NeedAssistance />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          <>
            <Header />
            <div className="middle_container">
              {!isDevicesPage && <SideBar />}
              <Routes>
                <Route
                  path="/healthStatus"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <HealthStatus />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/floors"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <FloorMap newref={newref} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/floorManagement"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <FloorManagement />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manageUser"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <MangeUser />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/devices"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <Devices />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/device"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <DevicesComp />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/cameraFeed"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <CameraFeed />
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                  path="/analytics"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <Analytics />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/help"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <Help />
                    </ProtectedRoute>
                  }
                /> */}
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/globalSettings"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <GlobalSetting />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/floorManagement/assignDevices/:id"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthentication}>
                      <AddDevices />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/floors" replace />} />

                {/* Add all other routes here in a similar pattern */}
                <Route path="*" element={<Navigate to="/floors" replace />} />
              </Routes>
            </div>
          </>
        )}
        {isInactive && modalActive && userDataStorage !== "" && (
          <SessionWarning
            timeRemaining={timeRemaining}
            onStayLoggedIn={resetSession}
            onLogout={handleLogout}
            isInactive={isInactive}
          />
        )}
      </div>
      <Snackbar
        open={isAuthentication && enableSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          zIndex: 1200,
          pointerEvents: "none",
          position: "absolute"
        }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          Drill mode is enabled
        </Alert>
      </Snackbar>
    </DndProvider>
  );
}

export default App;
