import React, { useState, useEffect } from "react";
import { Box, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import mfa_call from "../../assets/images/mfa_call.png";
import mfa_mail from "../../assets/images/mfa_mail.png";
import mfa_shield from "../../assets/images/mfa_shield.png";
import "./MFADialog.scss";
import AuthenticatorAppDialog from "./AuthenticatorAppDialog";
import MFALogin from "./MFALogin";
import EmailDialog from "./EmailDialog";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../../Actions/progressActions";
import ProgressBar from "./ProgressBar";
import { setRememberDeviceCheck, resetRememberDeviceState } from '../../reducers/RememberDeviceReducer';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  paddingLeft: 4,
  paddingRight: 4,
};
const styleBar = {
  marginTop: "20px",
};
const styleProgress = {
  justifyContent: "space-between",
  display: "flex",
};
const MFADialog = ({
  open,
  onClose,
  setCloseModalValidation,
  closeModalValidation,
}) => {
  const [isAuthDialogOpen, setAuthDialogOpen] = useState(false);
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [mfaResponseData, setMfaResponseData] = useState();
  const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const rememberDeviceCheck = useSelector((state) => state.rememberDevice.rememberDeviceCheck);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgress(30));
  }, [dispatch]);

  const handleClose = () => {
    onClose(); // Close the modal when cancel is clicked
    setSelectedMethod(""); // Reset selected method
    dispatch(resetRememberDeviceState());
    setEmailDialogOpen(false);
    setAuthDialogOpen(false);
    setLoginDialogOpen(false);
  };

  const handleMethodSelection = (method) => {
    setSelectedMethod(method);
  };

  const handleCancelDialog = () => {
    setEmailDialogOpen(false);
    setAuthDialogOpen(false);
    setLoginDialogOpen(false);
    setSelectedMethod(""); 
    dispatch(setProgress(30));
    dispatch(resetRememberDeviceState());
  };

  const handleNextClick = async () => {
    try {
      setCloseModalValidation(false);
      setSelectedMethod("");
      setEmailDialogOpen(false);
      setAuthDialogOpen(false);
      setLoginDialogOpen(false);

      if (selectedMethod === "email") {
        await handleEmailClick();
      } else if (selectedMethod === "authenticator") {
        handleAuthenticatorAppClick();
      } else {
        console.warn("No valid verification method selected.");
      }
    } catch (error) {
      console.error("Error preparing verification:", error);
    }
  };

  const handleEmailClick = async () => {
    setEmailDialogOpen(false);
    setAuthDialogOpen(false);
    setLoginDialogOpen(false);

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/sendEmailVerifyCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: decodedToken.email,
          }),
        }
      );

      if (response.status === 200) {
        setEmailDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };

  const handleAuthenticatorAppClick = async () => {
    setEmailDialogOpen(false);
    setAuthDialogOpen(false);
    setLoginDialogOpen(false);

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    const userGuid = decodedToken.userGuid;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/authenticate_user_live`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: decodedToken.email,
            userGuid: userGuid,
          }),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        if (data.mfaSetupStatus === true) {
          setIsFirstLogin(false);
          setLoginDialogOpen(true); // Open login dialog
        } else if (data.mfaSetupStatus === false) {
          setIsFirstLogin(true);
          setMfaResponseData(data.secretImageUri);
          setAuthDialogOpen(true); // Open Auth app dialog
        } else {
          console.error(
            "Unexpected mfaSetupStatus value:",
            data.mfaSetupStatus
          );
        }
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    dispatch(setRememberDeviceCheck(e.target.checked));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleCancelDialog(); // Ensure progress is reduced when modal is closed
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="addFlorModal"
    >
      <Box sx={style}>
        <div className="" style={styleBar}>
          <div style={styleProgress}>
            <span className="progress-text">Login Progress</span>
            <IconButton
              onClick={() => {
                onClose();
                dispatch(setProgress(30));
                dispatch(resetRememberDeviceState());
              }}
              className="icon-container"
            >
              <CloseIcon className="icon" />
            </IconButton>
          </div>

          <ProgressBar />
        </div>
        <div className="dialog-title">
          <h2 className="dialog-title-text">Multi-Factor Authentication</h2>
        </div>
        {isEmailDialogOpen && !closeModalValidation ? (
          <EmailDialog onCancel={handleCancelDialog} />
        ) : isAuthDialogOpen && !closeModalValidation ? (
          <AuthenticatorAppDialog
            barcode={mfaResponseData}
            isFirstLogin={isFirstLogin}
            onCancel={handleCancelDialog}
          />
        ) : isLoginDialogOpen && !closeModalValidation ? (
          <MFALogin onCancel={handleCancelDialog} />
        ) : (
          <>
            <div className="dialog-content">
              <div className="dialog-space">
                <div className="dialog-description">
                  <p>
                    Prevent hackers from accessing your account with an
                    additional layer of security. When you sign in, 2-Step
                    verification helps ensure your personal information stays
                    private, safe, and secure.
                  </p>
                  <p>Choose the preferred verification method.</p>
                </div>
              </div>
              <Button
                fullWidth
                className="mfa-button"
                disabled
                style={{ border: "1px dashed #5b5d75", marginBottom: "16px" }}
              >
                <div className="button-content">
                  <img src={mfa_call} alt="Call Icon" className="button-icon" />
                  <div className="button-text">Verification by Phone/SMS</div>
                </div>
              </Button>

              <Button
                fullWidth
                className={`mfa-button ${
                  selectedMethod === "email" ? "selected" : ""
                }`}
                style={{
                  border: "1px dashed #5b5d75",
                  marginBottom: "16px",
                  backgroundColor:
                    selectedMethod === "email" ? "#e6f7ff" : "inherit",
                }}
                onClick={() => handleMethodSelection("email")}
              >
                <div className="button-content">
                  <img src={mfa_mail} alt="Mail Icon" className="button-icon" />
                  <div className="button-text">Verification by Email</div>
                </div>
              </Button>

              <Button
                fullWidth
                className={`mfa-button ${
                  selectedMethod === "authenticator" ? "selected" : ""
                }`}
                style={{
                  border: "1px dashed #5b5d75",
                  backgroundColor:
                    selectedMethod === "authenticator" ? "#e6f7ff" : "inherit",
                }}
                onClick={() => handleMethodSelection("authenticator")}
              >
                <div className="button-content">
                  <img
                    src={mfa_shield}
                    alt="Authenticator Icon"
                    className="button-icon"
                  />
                  <div className="button-text">
                    Verification by Authenticator App
                  </div>
                </div>
              </Button>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="rememberDeviceCheck"
                  checked={rememberDeviceCheck}
                  onChange={handleCheckboxChange}
                  className="check"
                />
                <label htmlFor="rememberDeviceCheck" className="rem-text">
                  Remember this device
                </label>
              </div>
            </div>
            {/* Next Button */}
            <div className="mfa-save">
              <Button
                variant="outlined"
                className="cancelBtn"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNextClick()}
                style={{ marginRight: "32px" }}
                className="save"
              >
                Next
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MFADialog;
