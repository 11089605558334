import React, { useState, useEffect } from 'react';
import { Box, Snackbar, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '../../assets/images/editIcon.svg';
import DeleteIcon from '../../assets/images/DeleteIcon.svg';
import AddEditUser from './AddEditUser'
import DeleteModal from './DeleteModal'
import { ListUserAction, DeleteUserAction, DeleteReset } from '../../Actions/AddEditUserAction'
import './mustyle.scss'
import { useDispatch, useSelector } from 'react-redux';

const MangeUser = () => {
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [actionButton, setActionButton] = useState("")
    const [editData, setEditData] = useState(null);
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const users = useSelector((state) => state.userList);
    const dispatch = useDispatch();
    const columns = [
        {
            field: 'userid',
            headerName: 'User Id',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'emailaddress',
            headerName: 'Email Address',
            flex: 2,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            sortable: true,
            disableColumnMenu: false,
            hideable: false,
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            sortable: false,
            disableColumnMenu: true,
            hideable: false,
            renderCell: (params) => (
                <div>
                    <img
                        src={EditIcon}
                        alt="edit"
                        style={{ width: '28px', height: '28px', cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handleEdit(params.row)}
                    />
                    <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                        onClick={() => { setOpenDeleteModal(true); setDeviceIdToDelete(params.row.userid) }}
                    />
                </div>
            ),
        }
    ]
    const handleEdit = (deviceData) => {
        setEditData(deviceData);
        setActionButton("edit");
        setOpen(true);
    };

    const processedRows = Array.isArray(rows) 
    ? rows
        .slice() // Create a copy to avoid mutating the original array
        .sort((a, b) => (a.firstName || '').localeCompare(b.firstName || '')) // Sort by firstName
        .map((row, index) => ({
            id: index + 1, // Keeps the sequence number only for internal tracking
            userid: row.userGuid || 'N/A',
            name: `${row.firstName} ${row.lastName}`.trim() || 'N/A',
            emailaddress: row.email || 'N/A',
            role: row.roleName || 'N/A',
            status: row.userStatus || 'N/A',
        })) 
    : [];

    useEffect(() => {
        if(!users.isLoading && users.data !== null) {
            setRows(users.data)
        }
    }, [users]);

    useEffect(() => {
        dispatch(ListUserAction());
    }, [ListUserAction, dispatch]);

    const handleDelete = async (id) => {
        dispatch(DeleteUserAction(id));
    };
    return (
        <>
            <div className="userMangement_wrapper">
                <div
                    className="main_heading"
                >
                    <h1>Manage Users</h1>
                </div>
                <div className="AddUser">
                    <button name="Create New User" className="addDevice" onClick={() => { setOpen(true); setActionButton("add") }}>Create New User</button>
                </div>
                <div className="inner_Wrapper">
                    <div className="floorMangementTable_wrapper manageUserTable_wrapper">
                        {users.isLoading ? <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                            : <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={processedRows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        }
                    </div>
                </div>
            </div>
            <AddEditUser
                open={open}
                setOpen={setOpen}
                setActionButton={setActionButton}
                actionButton={actionButton}
                editData={editData}
            />
            <DeleteModal
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                handleDelete={() => handleDelete(deviceIdToDelete)}
            />
            <Snackbar
                open={users.isDeleted}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                message={'Delete Successfully.'}
                onClose={() => dispatch(DeleteReset())}
                className={"success"}
            />
        </>

    )
}

export default MangeUser
