import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Modal,
    ListItemText,
    Checkbox,
    FormHelperText,
    CircularProgress,
} from '@mui/material';
import closeIcon from '../../assets/images/closeicon.svg';
import { fieldUser } from "./FieldJson";
import axios from "axios";
import attachmentIcon from "../../assets/images/attachmentIcon.png";
import { jwtDecode } from "jwt-decode";
import './style.scss';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};

const AddEditFloor = (props) => {
    const [formData, setFormData] = useState({
        floor: '',
        // floorPlan: '',
        uploadedFile: null,
        selectedAdmins: []
    });
    const [formError, setFormError] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null); // Capture uploaded file
    // const [imagePreview, setImagePreview] = useState(null);
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false); // Loader state
    const { setOpen, open, actionButton, setActionButton, editData } = props;
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const fetchData = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${process.env.REACT_APP_API_URL}/getUserList`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setUserList(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        if (open) {
            fetchData()
        }
    }, [open])
    // Validation function
    const handleValidation = () => {
        let isValid = true;
        let errors = {};
        const requiredFields = actionButton === "edit"
            ? ["floor", "selectedAdmins"]
            : ["floor", "uploadedFile", "selectedAdmins"];
        requiredFields.forEach((field) => {
            if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
                isValid = false;
                if (field === "floor") {
                    errors[field] = "Please enter floor name";
                } else {
                    errors[field] = `Please enter ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
                }
            }
        });
        if (formData.floor && /[^a-zA-Z0-9\s]/.test(formData.floor)) {
            isValid = false;
            errors.floor = "Special characters are not allowed in the floor field.";
        }
        if (actionButton !== "edit" && (!formData.uploadedFile || formData.uploadedFile.length === 0)) {
            isValid = false;
            errors.uploadedFile = "Please upload floor plan file";
        }
        if (!formData.selectedAdmins || formData.selectedAdmins.length === 0) {
            isValid = false;
            errors.selectedAdmins = "Please select Configuration Administrator";
        }
        if (!isValid) {
            setFormError(errors);
        } else {
            setFormError({});
        }

        return isValid;

    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const allowedExtensions = ["image/jpeg", "image/png"];
        const maxSizeInBytes = 2 * 1024 * 1024;
    
        if (file) {
            // Check file type
            if (!allowedExtensions.includes(file.type)) {
                setFormError((prevState) => ({
                    ...prevState,
                    uploadedFile: "Only .jpg and .png files are allowed.",
                }));
                setUploadedFile(null);
                setFormData((prevState) => ({
                    ...prevState,
                    uploadedFile: null,
                }));
                return;
            }
    
            // Check file size
            if (file.size > maxSizeInBytes) {
                setFormError((prevState) => ({
                    ...prevState,
                    uploadedFile: "File size must not exceed 2 MB.",
                }));
                setUploadedFile(null);
                setFormData((prevState) => ({
                    ...prevState,
                    uploadedFile: null,
                }));
                return;
            }
    
            // Check image dimensions
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width <= img.height) {
                    setFormError((prevState) => ({
                        ...prevState,
                        uploadedFile: "Image format is not correct. Width must be greater than height.",
                    }));
                    setUploadedFile(null);
                    setFormData((prevState) => ({
                        ...prevState,
                        uploadedFile: null,
                    }));
                } else {
                    setUploadedFile(file);
                    setFormData((prevState) => ({
                        ...prevState,
                        uploadedFile: file,
                    }));
                    setFormError((prevState) => ({
                        ...prevState,
                        uploadedFile: null,
                    }));
                }
            };
        }
    };
    

    const handleAdminChange = (event) => {
        const value = event.target.value;
        const selectedAdmins = value.map(guid => {
            const admin = userList.find(user => user.userGuid === guid);
            return {
                userGuid: guid,
                name: admin ? `${admin.firstName} ${admin.lastName}` : ''
            };
        });
        setFormData(prevState => ({
            ...prevState,
            selectedAdmins
        }));
    };

    const handleAdd = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const floorUsers = formData.selectedAdmins.map(admin => ({
                userGuid: admin.userGuid,
                name: admin.name
            }));
            const url = new URL(`${process.env.REACT_APP_DEVICE_API_URL}/floor/addFloor`);
            url.searchParams.append('floorName', formData.floor);
            url.searchParams.append('createdBy', decodedToken.email);
            const formDataToSend = new FormData();
            if (uploadedFile) {
                formDataToSend.append('floorImage', uploadedFile);
            }
            formDataToSend.append('floorUsers', JSON.stringify(floorUsers));
            const response = await axios.post(url.toString(), formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 201) {
                setOpen(false);
                setActionButton("");
                setFormError("");
                setFormData({
                    floor: '',
                    uploadedFile: null,
                    selectedAdmins: []
                });
                setUploadedFile(null)
            }
        } catch (error) {
            console.error("Error making request:", error);
            if (error?.response?.data?.message === "Floor with same name already exists") {
                setFormError({
                    ...formError,
                    floor: "Floor with same name already exists"
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const floorUsers = formData.selectedAdmins.map(admin => ({
                userGuid: admin.userGuid,
                name: admin.name
            }));

            const formDataToSend = new FormData();
            formDataToSend.append('floorName', formData.floor);
            formDataToSend.append('createdBy', decodedToken.email);
            // Attach uploaded file if exists
            if (uploadedFile) {
                formDataToSend.append('floorImage', uploadedFile);
            }
            formDataToSend.append('floorUsers', JSON.stringify(floorUsers));
            const response = await axios.put(`${process.env.REACT_APP_DEVICE_API_URL}/floor/update/${editData.id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setOpen(false);
                setActionButton("");
                setFormError("");
                setFormData({
                    floor: '',
                    uploadedFile: null,
                    selectedAdmins: []
                });
                setUploadedFile(null);
            }
        } catch (error) {
            console.error("Error updating floor:", error);
            if (error?.response?.data?.message === "Floor with same name already exists") {
                setFormError({
                    ...formError,
                    floor: "Floor with same name already exists"
                });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) {
            return;
        }
        if (actionButton === 'add') {
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("");
        setFormData({
            floor: '',
            uploadedFile: null,
            selectedAdmins: []
        });
        setUploadedFile(null)
    };

    useEffect(() => {
        if (actionButton === 'edit' && editData) {

            const floorPlan = editData.floorPlan || '';
            const uploadedFileName = floorPlan.split('/').pop();

            const selectedAdmins = Array.isArray(editData.ConfigurationAdministrator)
                ? editData.ConfigurationAdministrator.map(name => {
                    const admin = userList.find(user => `${user.firstName} ${user.lastName}` === name);
                    return admin ? { userGuid: admin.userGuid, name: `${admin.firstName} ${admin.lastName}` } : null;
                }).filter(Boolean)
                : [];

            setFormData({
                floor: editData.floor || '',
                uploadedFileName: uploadedFileName,
                selectedAdmins: selectedAdmins || [],
                floorPlan: floorPlan
            });
        } else {
            setFormData({
                floor: '',
                uploadedFileName: null,
                selectedAdmins: [],
                floorPlan: ''
            });
        }
    }, [actionButton, editData, userList]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit Floor Management" : "Add Floor Management"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle addDeviceMiddle AddFloorModal login-form">
                    <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                        {fieldUser.map((data, index) => {
                            return (
                                <>
                                    {
                                        data.type === "input" ? (
                                            <TextField
                                                key={index}
                                                id={data.id}
                                                name={data.name}
                                                label={data.label}
                                                variant="outlined"
                                                onChange={handleInputChange}
                                                value={formData[data.name] || ''}
                                                error={!!formError[data.name]}
                                                className="innerFields"
                                                helperText={formError[data.name] || data.helperText}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: { transform: 'inherit' },
                                                }}
                                            />
                                        ) : data.type === "attach" ? (
                                            <TextField
                                                key={index}
                                                label={data.label}
                                                value={(actionButton === "add" && uploadedFile?.name) || (actionButton === "edit" && formData.uploadedFileName) || ''} // Change this to uploadedFileName
                                                helperText={formError.uploadedFile}
                                                className="innerFields attachmentField"
                                                InputProps={{
                                                    readOnly: true, // Prevents manual input
                                                    endAdornment: (
                                                        <Button variant="contained" component="label" className="attachmentBtn">
                                                            <img src={attachmentIcon} alt="img" />
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept="image/*"
                                                                onChange={handleFileUpload}
                                                            />
                                                        </Button>
                                                    ),
                                                }}
                                            />
                                        ) : (
                                            <FormControl className="innerFields" fullWidth>
                                                <InputLabel>Configuration Administrator</InputLabel>
                                                <Select
                                                    labelId="admin-select-label"
                                                    id="admin-select"
                                                    multiple
                                                    value={formData.selectedAdmins.map(admin => admin.userGuid)}
                                                    onChange={handleAdminChange}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>Select an Administrator</span>;
                                                        }
                                                        // Display selected usernames as a single string
                                                        return selected.map(guid => {
                                                            const admin = userList.find(user => user.userGuid === guid);
                                                            return admin ? `${admin.firstName} ${admin.lastName}` : '';
                                                        }).join(', ');
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: '300px',
                                                                maxWidth: '0px',
                                                                overflowY: 'auto',
                                                                overflowX: 'auto',
                                                                marginTop: '25%'
                                                            },
                                                        }

                                                    }}
                                                >
                                                    {userList.map((admin) => (
                                                        <MenuItem key={admin.userGuid} value={admin.userGuid}> {/* Use userGuid for a unique value */}
                                                            <Checkbox checked={formData.selectedAdmins.some(selected => selected.userGuid === admin.userGuid)} />
                                                            <ListItemText primary={`${admin.firstName} ${admin.lastName}`} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {/* Add FormHelperText */}
                                                <FormHelperText>
                                                    {formError.selectedAdmins}
                                                </FormHelperText>
                                            </FormControl>

                                        )
                                    }
                                </>
                            );
                        })}
                    </Box>
                </div>
                <div className="Modal_footer adddeviceFooter">
                    <Box sx={{ display: 'flex' }}>

                        <Button variant="outlined" className="cancelBtn" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={handleSubmit}>
                            Save
                        </Button>
                        {loading && (
                            <div className="loader-overlay">
                                <CircularProgress size={50} />
                            </div>
                        )}
                    </Box>
                </div>
            </Box>
        </Modal>
    );
};

export default AddEditFloor;
