import React from "react";
import { useSelector } from "react-redux";

const ProgressBar = () => {
  const progress = useSelector((state) => state.progress.progress);

  return (
    <div className= "progress-bar" style={{ }}>
      <div
        style={{
          width: `${progress}%`,
          height: "10px",
          backgroundColor: "#037E3A",
          transition: "width 0.3s ease-in-out",
          borderRadius: "20px"
        }}
      />
    </div>
  );
};

export default ProgressBar;
