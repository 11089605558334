import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { LoginValue } from "../../Actions/LoginPageAction";
import { useDispatch, useSelector } from "react-redux";
import "./MFALogin.css";
import { jwtDecode } from "jwt-decode";
import checkImg from "../../assets/images/check_circle.svg";
import BackupLogin from "./BackupLogin";
import { setProgress } from "../../Actions/progressActions";
import { setRememberDeviceCheck, resetRememberDeviceState } from '../../reducers/RememberDeviceReducer';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const EmailDialog = ({ open, onClose, onCancel }) => {
  const [code, setCode] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enableNextBtn, setEnableNextBtn] = useState(false);
  const [message, setMessage] = useState("");
  const [successDialog, setSuccessDialogOpen] = useState(false);
  const [showBackupLogin, setShowBackupLogin] = useState(false);
  const dispatch = useDispatch();
  const verifyButtonRef = useRef(null);
  const rememberDeviceCheck = useSelector((state) => state.rememberDevice.rememberDeviceCheck);

  useEffect(() => {
    if (!successDialog) {
      dispatch(setProgress(60));
    } else {
      dispatch(setProgress(100));
    }
  }, [dispatch]);

  const getDeviceId = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const deviceId = result.visitorId;
    return deviceId;
  };

  const handleResendClick = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/sendEmailVerifyCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: decodedToken.email,
          }),
        }
      );

      if (response.status === 200) {
        setMessage("Code has been resent.");
      } else {
        setMessage("Failed to resend the code. Please try again.");
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
    }
  };

  const handleNext = async () => {
    if (rememberDeviceCheck) {
      try {
        const deviceId = await getDeviceId();
        
        const token = JSON.parse(localStorage.getItem("token"));
        const decodedToken = jwtDecode(token);
        const userGuid = decodedToken.userGuid;
        
        const requestBody = {
          rememberDeviceCheck,
          deviceId,
          userGuid,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/addRememberDevice`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          console.error("Failed to remember device.");
        }
      } catch (error) {
        console.error("Error fetching deviceId or calling API:", error);
      }
    }
    
    dispatch(setProgress(100));
    setSuccessDialogOpen(true);
  };

  const handleBackupCodeClick = () => {
    setShowBackupLogin(true); // Show the BackupLogin component
  };

  const handleCancelBackup = () => {
    setShowBackupLogin(false); // Go back to EmailDialog
    dispatch(resetRememberDeviceState());
  };

  const handleCodeVerification = async () => {
    if (!code || code.length !== 6) {
      setErrorMessage(
        !code
          ? "Please enter the verification code."
          : "Please enter a 6-digit code."
      );
      return;
    }

    const token = JSON.parse(localStorage.getItem("token"));
    const decodedToken = jwtDecode(token);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/verifyViaEmailCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: decodedToken.email,
            code,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.verifyCode === true) {
        setMessage("Verification successful!");
        setErrorMessage("");
        setEnableNextBtn(true);
      } else {
        setErrorMessage("Verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Failed to fetch authentication status:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleDone = () => {
    // Mark MFA as completed
    localStorage.setItem("isMFACompleted", "true");
    // Mark user as authenticated
    localStorage.setItem("isAuthenticated", "true");
    // Save the default path
    localStorage.setItem("lastPath", "/floors");
    // Dispatch Redux action and navigate
    dispatch(LoginValue(true));
    dispatch(resetRememberDeviceState());
    // navigate("/dashboard", { replace: true });
  };

  return (
    <>
      {!isVerified && !successDialog && !showBackupLogin ? (
        <>
          <div className="dialog-content emailDailog_wrapper">
            <p>
              Please enter the verification code that was sent to your email.
            </p>
            <div className="authenticator-input">
              <div className="label-text">Verification Code</div>
              <div className="align-buttons">
                <input
                  type="text"
                  className="text-box"
                  value={code}
                  placeholder="XXXXXX"
                  onChange={(e) => {
                    setCode(e.target.value);
                    setMessage(""); // Clear the success message
                    setErrorMessage(""); // Clear the error message
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      verifyButtonRef.current.focus();
                      handleCodeVerification();
                    }
                  }}
                  maxLength="6"
                />
                <div className="send-code-button">
                  <Button
                    color="primary"
                    ref={verifyButtonRef}
                    className="btn-text"
                    onClick={() => {
                      setMessage(false); // Set message to false
                      handleCodeVerification(); // Call the function
                    }}
                  >
                    Verify
                  </Button>
                  <Button
                    color="primary"
                    className="btn-text"
                    onClick={() => {
                      handleResendClick();
                      setErrorMessage(false);
                    }}
                  >
                    Resend
                  </Button>
                </div>
              </div>
            </div>
            <div onClick={handleBackupCodeClick} className="loginViacode">
              Login via backup code
            </div>
            {message && (
              <div style={{ color: "green", marginTop: "20px" }}>{message}</div>
            )}
            {errorMessage && (
              <div style={{ color: "red", marginTop: "20px" }}>
                {errorMessage}
              </div>
            )}
          </div>
          {!isVerified ? (
            <div className="mfa-save">
              <Button
                variant="outlined"
                className="cancelBtn"
                onClick={() => {
                  dispatch(resetRememberDeviceState());
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className={`disabled-button ${
                  !enableNextBtn ? "disbleBtn" : ""
                } `}
                disabled={!enableNextBtn}
                onClick={() => handleNext()}
              >
                Next
              </Button>
            </div>
          ) : (
            <Button
              color="primary"
              className="next-button"
              style={{ top: "40%", right: "15px" }}
              onClick={() => handleDone()}
            >
              Done
            </Button>
          )}
        </>
      ) : showBackupLogin ? (
        <BackupLogin onCancel={handleCancelBackup} />
      ) : (
        <div className="succes_modal">
          <img src={checkImg} alt="Success" />
          <h2>Success</h2>
          <p>Your verification is successfully completed.</p>

          <div className="mfa-save">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDone}
              className="done-btn"
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailDialog;
