const initialState = {
    javaServerStatus: 'critical',
    cameraData: [],
    cameraStatus: "critical",
    detailedViewData: [],
    loading: false,
    loadingCamera: false,
    loadingJava:false,
    error: null,
};
const HealthStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case "JAVA_DATA_REQUEST":
            return {
                ...state,
                loadingJava: true,
            };
        case "JAVA_DATA_SUCCESS":
            return {
                ...state,
                javaServerStatus: action.payload,
                loadingJava: false,
            };
        case "JAVA_DATA_FAILURE":
            return {
                ...state,
                javaServerStatus: action.payload,
                loadingJava: false,
            };
        case 'FETCH_CAMERA_REQUEST':
            return {
                ...state,
                loadingCamera: true,
            };
        case 'FETCH_CAMERA_SUCCESS':
            return {
                ...state,
                cameraData: action.payload,
                loadingCamera: false,
                error: null,
            };
        case 'FETCH_CAMERA_FAIL':
            return {
                ...state,
                cameraData: [],
                loadingCamera: false,
                error: "Failed to fetch camera data",
            };
        case 'SET_CAMERA_STATUS':
            return {
                ...state,
                cameraStatus: action.payload,
            };
        case 'FETCH_DETAILED_VIEW_REQUEST':
            return {
                ...state,
                loading: true,
                error: null
            };
        case 'FETCH_DETAILED_VIEW_SUCCESS':
            return {
                ...state,
                loading: false,
                detailedViewData: action.payload
            };
        case 'FETCH_DETAILED_VIEW_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'CLEAR_DETAILED_VIEW':
            return {
                ...state,
                detailedViewData: []
            };
        default:
            return state;
    }
};

export default HealthStatusReducer;
