const initialState = {
  isPlaying: false,
  audioSrc: null,
  startTime: 0, 
  duration: 0, 
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PLAY_AUDIO":
      return {
        ...state,
        isPlaying: true,
        audioSrc: action.payload,
        startTime: Date.now(),
        duration: action.duration || 0,
      };
    case "UPDATE_AUDIO_DURATION":
      return {
        ...state,
        duration: action.payload,
      };
    case "STOP_AUDIO":
      return {
        ...state,
        isPlaying: false,
        startTime: 0,
      };
    default:
      return state;
  }
};

export const playAudio = (payload, duration) => ({
  type: "PLAY_AUDIO",
  payload,
  duration,
});

export const updateAudioDuration = (duration) => ({
  type: "UPDATE_AUDIO_DURATION",
  payload: duration,
});

export const stopAudio = () => ({
  type: "STOP_AUDIO",
});

export default audioReducer;
