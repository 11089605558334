import React, { useState, useEffect, useRef, useMemo } from "react";
import "./style.scss";
import axios from "axios";
import {
  Box,
  CircularProgress,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import silence from "../../assets/sounds/silence.mp3";
import alertIcon from "../../assets/images/alertIcon.svg";
import deactivateIcon from "../../assets/images/deactivateIcon.svg";
import construction from "../../assets/images/construction.svg";
import constructionWhite from "../../assets/images/constructionWhite.svg";
import sirenSound from "../../assets/sounds/siren.mp3";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateFloorData, floorMapRef, setRowsHandler, fetchSettings } from "../../Actions/FloormapAction";
import { styled } from "@mui/material/styles";
import mqtt from "mqtt";
import OverrideDevice from "./OverrideDevice.js";

const TooltipContent = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
});
const ImageTooltipContent = () => (
  <TooltipContent>
    <img src={constructionWhite} style={{ display: "block" }} />
    <div>Need Repair</div>
  </TooltipContent>
);

const FloorMap = () => {
  const [printView, setPrintView] = useState(false);
  const [ids, setIds] = useState(0);
  // const [rows, setRows] = useState([]);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [floorDetails, setFloorDetails] = useState({});
  const dispatch = useDispatch();
  const [triggerBtn, setTriggerBtn] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const mqttClient = useRef(null);
  const [floorData, setFloorData] = useState([]);
  const [currentFloor, setCurrentFloor] = useState(1);
  const [overRideDeviceModal, setOverRideDeviceModal] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [hoveredDeviceId, setHoveredDeviceId] = useState(null);
  const [successVerified, setSuccessVerified] = useState(null);
  const [deviceOverrideId, setDeviceOverrideId] = useState({});
  const [allDevices, setAllDevices] = useState([]);
  const [clearAlarmsTriggered, setClearAlarmsTriggered] = useState(false);
  const [resetDmpActive, setResetDmpActive] = useState(false);
  const [resetDmpTriggered, setResetDmpTriggered] = useState(false);
  const [mapWithNiagraList, setMapWithNiagraList] = useState([]);
  const [needRepairStaus, setNeedRepairStaus] = useState(false);
  const [unixTime, setUnixTime] = useState("");
  const [overrideDeviceHeading, setOverrideDeviceHeading] = useState("Deactivate Device");
  const [triggerForEmail, setTriggerForEmail] = useState(false);
  const enableSnackbar = useSelector((state) => state.snackbar.enableSnackbar);
  const [deviceIds, setDeviceIds] = useState("");
  const [pushButtonIds, setPushButtonIds] = useState([]);
  const [fualtyButtonId, setFualtyButtonId] = useState([]);
  const [shouldPlayAlarm, setShouldPlayAlarm] = useState(false);

  // const audioRef = useRef(null);
  const [globalSettings, setGlobalSettings] = useState([]);
  const [alarmTimestamp, setAlarmTimestamp] = useState("");
  const { activeFloorRef, activeFloorData, activeFloorPRintData, rows, isDrillSpkAndStrobe } = useSelector((state) => state.floormap);

  const lockdownDevices = (
    Array.isArray(floorData?.devices) ? floorData.devices : []
  )
    .filter((device) => device.state === 3)
    .map((device) => device.deviceId);
  const hasLockedDevices = (
    Array.isArray(floorData?.devices) ? floorData.devices : []
  ).some((device) => device.state === 3);
  useEffect(() => {
    if (lockdownDevices.length > 0) {
      localStorage.setItem("isLockdownActive", "true");
    } else {
      localStorage.setItem("isLockdownActive", "false");
    }
  }, [lockdownDevices]);

  const memoizedImage = useMemo(() => {
    if (floorDetails?.floorImageUrl) {
      var str = floorDetails?.fileName
        ? `${process.env.REACT_APP_TESTPANEL_IMAGENAME}${floorDetails?.fileName}`.toString()
        : floorDetails?.floorImageUrl.toString();
      return str;
    }
  }, [activeTab, floorDetails, rows]);

  const ref = useRef(activeFloorRef);
  useEffect(() => {
    dispatch(setRowsHandler());
  }, []);
  const activeFloorDetails = (id) => {
    setActiveTab(id);
    setCurrentFloor(id);
  };

  useEffect(() => {
    setActiveTab(activeFloorData);
    dispatch(floorMapRef(ref));
  }, [activeFloorData]);

  const isDeviceVisible = (state) => {
    return true;
  };

  useEffect(() => {
    const fetchGlobalSettings = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        const settingsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`
        );

        console.log("Settings API Response:", settingsResponse);

        if (settingsResponse?.data?.allGlobalSettings) {
          const globalSettings = settingsResponse.data.allGlobalSettings;
          // Find playSetting and parse its value
          const playSetting = globalSettings.find(
            (setting) => setting.code === "playSetting"
          );
          let updatedSettings = [...globalSettings];
          if (playSetting) {
            try {
              const parsedValue = JSON.parse(playSetting.value);
              if (parsedValue.type === "0") {
                updatedSettings.push({
                  code: "playCount",
                  value: parsedValue.value,
                });
              } else if (parsedValue.type === "1") {
                updatedSettings.push({
                  code: "timeDuration",
                  value: parsedValue.value,
                });
              }
            } catch (error) {
              console.error("Error parsing playSetting value:", error);
            }
          }
          setGlobalSettings(updatedSettings);
        } else {
          console.error("Invalid API response format:", settingsResponse);
        }
      } catch (error) {
        console.error("Error fetching global settings:", error);
      }
    };
    fetchGlobalSettings();
  }, []);
  // Dont remove this code releated to audio
//  const memoizedAlarmSound = useMemo(() => {
//     //console.log("Sound play");

//     const fetchAlarmSound = async () => {
//       try {
//         let token = JSON.parse(localStorage.getItem("token"));
//         const url = `${process.env.REACT_APP_API_URL}/getAllAlarmMedia`;
//         const response = await axios({
//           method: "GET",
//           url: url,
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.data && Array.isArray(response.data)) {
//           const soundTypes = [
//             "default",
//             "drillStart",
//             "drillEnd",
//             "duringDrill",
//           ];
//           const alarmSounds = soundTypes.reduce((acc, type) => {
//             const foundSound = response.data.find(
//               (alarm) => alarm[type] === true
//             );
//             acc[type] = foundSound
//               ? foundSound.filepath
//               : type === "default"
//               ? sirenSound
//               : "";
//             return acc;
//           }, {});
//           return alarmSounds;
//         } else {
//           console.warn("No alarm sound data found.");
//           return { default: "", drillStart: "", drillEnd: "", duringDrill: "" };
//         }
//       } catch (error) {
//         console.error("Error fetching alarm sound:", error);
//         return { default: "", drillStart: "", drillEnd: "", duringDrill: "" };
//       }
//     };

//     return fetchAlarmSound();
//   }, []);

  // const drillAudioRef = useRef(null);
  // const alarmAudioRef = useRef(null);

  // useEffect(() => {
  //   let drillAudio;
  //   let alarmAudio;

  //   memoizedAlarmSound.then(({ default: soundPath, duringDrill }) => {
  //     // Stop any currently playing audio
  //     if (drillAudioRef.current) {
  //       drillAudioRef.current.pause();
  //       drillAudioRef.current.currentTime = 0;
  //     }
  //     if (alarmAudioRef.current) {
  //       alarmAudioRef.current.pause();
  //       alarmAudioRef.current.currentTime = 0;
  //     }

  //     const playAlarmAudio = (soundPath, playSetting) => {
  //       if (!soundPath || !playSetting) return;

  //       console.log("reached");
  //       try {
  //         const parsedValue = JSON.parse(playSetting.value);
  //         const { type, value } = parsedValue;
  //         alarmAudio = new Audio(soundPath);
  //         alarmAudio.preload = "auto";

  //         alarmAudio.addEventListener("loadedmetadata", () => {
  //           const audioDuration = alarmAudio.duration;
  //           if (type === "0") {
  //             // Play sound 'playCount' number of times
  //             const playTimes = parseInt(value, 10);
  //             let playCount = 0;

  //             const playAudioMultipleTimes = () => {
  //               if (playCount < playTimes) {
  //                 alarmAudio.currentTime = 0;
  //                 alarmAudio
  //                   .play()
  //                   .catch((err) => console.error("Error playing audio:", err));
  //                 playCount += 1;
  //                 setTimeout(playAudioMultipleTimes, audioDuration * 1000);
  //               }
  //             };

  //             playAudioMultipleTimes();
  //           }

  //           if (type === "1") {
  //             // Play sound 'timeDuration' seconds
  //             const timeDuration = parseInt(value, 10);
  //             const repeatCount = Math.floor(timeDuration / audioDuration);
  //             const remainingTime = timeDuration % audioDuration;
  //             let currentPlayCount = 0;

  //             const playAudioForDuration = () => {
  //               if (currentPlayCount < repeatCount) {
  //                 alarmAudio.currentTime = 0;
  //                 alarmAudio
  //                   .play()
  //                   .catch((err) => console.error("Error playing audio:", err));

  //                 setTimeout(() => {
  //                   currentPlayCount += 1;
  //                   playAudioForDuration();
  //                 }, audioDuration * 1000);
  //               } else if (remainingTime > 0) {
  //                 alarmAudio.currentTime = 0;
  //                 alarmAudio
  //                   .play()
  //                   .catch((err) => console.error("Error playing audio:", err));

  //                 setTimeout(() => {
  //                   alarmAudio.pause();
  //                   alarmAudio.currentTime = 0;
  //                 }, remainingTime * 1000);
  //               }
  //             };

  //             playAudioForDuration();
  //           }
  //         });
  //       } catch (error) {
  //         console.error("Error parsing playSetting value:", error);
  //       }

  //       alarmAudioRef.current = alarmAudio;
  //     };

  //     const playSetting = globalSettings.find(
  //       (setting) => setting.code === "playSetting"
  //     );

  //     if (hasLockedDevices && enableSnackbar) {
  //       // Condition 1: Lockdown initiated over drill mode
  //       if (drillAudioRef.current) {
  //         drillAudioRef.current.pause();
  //         drillAudioRef.current.currentTime = 0;
  //       }
  //       console.log(playSetting, "setting base");
  //       playAlarmAudio(soundPath, playSetting);
  //     } else if (duringDrill && enableSnackbar && !hasLockedDevices) {
  //       // Condition 2: Only Drill Mode Active
  //       drillAudio = new Audio(duringDrill);
  //       drillAudio.preload = "auto";
  //       drillAudio.loop = true;
  //       drillAudio
  //         .play()
  //         .catch((err) =>
  //           console.error("Error playing during drill audio:", err)
  //         );

  //       drillAudioRef.current = drillAudio;
  //     } else if (hasLockedDevices && !enableSnackbar) {
  //       // Condition 3: Only Lockdown Initiated
  //       playAlarmAudio(soundPath, playSetting);
  //     }
  //   });

  //   return () => {
  //     if (drillAudioRef.current) {
  //       drillAudioRef.current.pause();
  //       drillAudioRef.current.currentTime = 0;
  //     }
  //     if (alarmAudioRef.current) {
  //       alarmAudioRef.current.pause();
  //       alarmAudioRef.current.currentTime = 0;
  //     }
  //   };
  // }, [memoizedAlarmSound, hasLockedDevices, enableSnackbar, globalSettings]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DEVICE_API_URL}/device/getAllLocationDevices`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const devices = response.data;
        setAllDevices(devices);
        setIsLoading(false);

        mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
          username: process.env.REACT_APP_MQTT_USERNAME,
          password: process.env.REACT_APP_MQTT_PASSWORD,
        });

        mqttClient.current.on("connect", () => {
          window.mqttConnected = true;
          mqttClient.current.subscribe(`${process.env.REACT_APP_NIAGARA_TOPIC}`);
        });
        
        mqttClient.current.on("message", (topic, message) => handleMessage(topic, message, devices));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
}, []);

const handleMessage = (topic, message, devices) => {
    const decodedMessage = new TextDecoder("utf-8").decode(message);
    console.log("Received MQTT message:", decodedMessage);
  
    const deviceId = parseInt(topic.split("/").pop());
    console.log(topic, deviceId, "received data::", decodedMessage);

    if (decodedMessage === "true" && topic.includes("status")) {
        setDeviceIds((prevIds) => [...new Set([...prevIds, deviceId])]);
        setPrintView(true);
        setTriggerForEmail(true);
        setAlarmTimestamp(new Date().toISOString());
    }
    
    if (topic.toLowerCase().startsWith("niagara/device/pushbutton/")) {
        let json = JSON.parse(decodedMessage);
        let pushButtonIdsTemp = [];
        let unixTimesTemp = [];
        
        setAllDevices(prevDevices => prevDevices.map(location => ({
          ...location,
          devices: location.devices.map(device => {
            let updatedState = device.state;
    
            Object.keys(json).forEach(key => {
              if (device.niagaraDeviceName === key) {
                let j = json[key];
                updatedState = j.health ? 1 : 2;
    
                if (!j.health) {
                  setNeedRepairStaus(true);
                  setResetDmpActive(true);
                  setFualtyButtonId(prevIds => [...new Set([...prevIds, device.deviceId])]);
                  setUnixTime(j.unixTime.toString());
                }
    
                if (j.status) {
                  pushButtonIdsTemp.push(device.deviceId);
                  unixTimesTemp.push(j.unixTime.toString());
                }
              }
            });
    
            return { ...device, state: updatedState };
          }),
        })));
    
        setPushButtonIds(prevIds => [...new Set([...prevIds, ...pushButtonIdsTemp])]);
        setUnixTime(prevTimes => [...new Set([...prevTimes, ...unixTimesTemp])]);
        dispatch(updateFloorData(devices));
    }
    if (deviceId) {
      setAllDevices(prevDevices => {
          const updatedDevices = prevDevices.map(location => ({
              ...location,
              devices: location.devices.map(device => 
                  device.deviceId === deviceId
                      ? { ...device, state: JSON.parse(decodedMessage) ? 3 : 1 }
                      : device
              ),
          }));
          // Dont remove this code releated to audio
          // **Check if any device has state === 3 and update shouldPlayAlarm**
          // // const anyDeviceActive = updatedDevices.some(location =>
          // //     location.devices.some(device => device.state === 3)
          // // );
          // setShouldPlayAlarm(anyDeviceActive);
  
          return updatedDevices;
      });
  
      dispatch(updateFloorData(devices));
  } else {
        console.error("Invalid deviceId:", deviceId);
    }
};

  // useEffect(() => {
  //   if (needRepairStaus && fualtyButtonId.length > 0) {
  //     const sendRequest = async () => {
  //       try {
  //         const token = JSON.parse(localStorage.getItem("token"));
  //         const payload = {
  //           deviceIds: fualtyButtonId,
  //           floorId: activeTab,
  //           unixTime: unixTime,
  //         }

  //         const response = await axios.post(
  //           `${process.env.REACT_APP_SERVER_API_URL}/email/faultDevice`,
  //           payload,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         if (response.status === 200) {
  //           console.log("POST request successful");
  //           setFualtyButtonId([]);
  //           setNeedRepairStaus(false);
  //           setUnixTime("")
  //         }
  //       } catch (error) {
  //         console.error("Error making POST request:", error);
  //       }
  //     };
  //     sendRequest();
  //   }
  // }, [fualtyButtonId]);

  useEffect(() => {
    const matcheddevices = allDevices.find(
      (devices) => devices.id === activeTab
    );
    if (matcheddevices) {
      setData(matcheddevices);
      setFloorData(matcheddevices);
    }
  }, [activeTab, allDevices]);

  const resetRequest = () => {
    mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
      username: process.env.REACT_APP_MQTT_USERNAME,
      password: process.env.REACT_APP_MQTT_PASSWORD,
    });
    mqttClient.current.on("connect", function () {
      window.mqttConnected = true;
      console.log("Connected to MQTT broker");
      mqttClient.current.subscribe("niagara/#");
    });
    const topic = "niagara/device/reset";
    const message = true;
    // Publish initial message
    mqttClient.current.publish(topic, JSON.stringify(message));
    console.log(floorData, "floorinalarm");
    // Publish false after 10 seconds
    setTimeout(() => {
      const resetMessage = false;
      mqttClient.current.publish(topic, JSON.stringify(resetMessage));
    }, 10000); // 10 seconds
  };

  useEffect(() => {
    if (activeTab) {
      if (Array.isArray(rows)) {
        const matchedFloor = rows.find((floor) => floor.id === activeTab);
        setFloorDetails(matchedFloor || rows[0]);
      }
    } else {
      setFloorDetails(rows[0]);
    }
  }, [activeTab, rows]);
  useEffect(() => {
    const message = "false";
    const topic = deviceOverrideId.topic;
    if (successVerified && clearAlarmsTriggered) {
      resetRequest();
    } else if (successVerified && resetDmpTriggered) {
      setResetDmpActive(false);
      resetRequest();
    } else if (successVerified && deviceOverrideId.topic) {
      if (mqttClient.current && mqttClient.current.connected) {
        mqttClient.current.publish(topic, message);
        setDeviceOverrideId({});
      } else {
        console.error("MQTT client is not connected.");
      }
    }
  }, [successVerified]);

  useEffect(() => {
    mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
      username: process.env.REACT_APP_MQTT_USERNAME,
      password: process.env.REACT_APP_MQTT_PASSWORD,
    });

    mqttClient.current.on("connect", function () {
      window.mqttConnected = true;
      console.log("Connected to MQTT broker");
      mqttClient.current.subscribe(`${process.env.REACT_APP_NIAGARA_TOPIC}`);
    });
    if (hasLockedDevices) {
      // dispatch for setting
      dispatch(fetchSettings());
      // get list for niagra devices
      mqttClient.current.on("message", function (topic, message) {
        try {
          let decodedMessage = new TextDecoder("utf-8").decode(message).trim();
          if (decodedMessage.startsWith("{") && decodedMessage.endsWith("}")) {
            const parsedMessage = JSON.parse(decodedMessage);
            setMapWithNiagraList((prevState) => ({
              ...prevState,
              ...parsedMessage,
            }));
          } else {
            console.error("Invalid JSON format received:", decodedMessage);
          }
        } catch (error) {
          console.error("Error parsing MQTT message:", error);
        }
      });
    }
  }, [hasLockedDevices]);

  const onClickResetDmp = () => {
    if (resetDmpActive) {
      setOverRideDeviceModal(true);
      setResetDmpTriggered(true)
      setOverrideDeviceHeading("Confirm Repair");
    }
  }

  return floorData ? (
    <div className={`floorMap_wrapper`}>
      <div className="main_heading">
        <h1>Floors</h1>
        <div className="blinkText">
          {(Array.isArray(floorData?.devices) ? floorData.devices : [])
            .filter((device) => device.state === 3)
            .map((device) => device.deviceId).length > 0 ? (
            enableSnackbar? <p>Lockdown (Drill Mode)</p> : <p>Lockdown</p> 
          )  : (
            ""
          )}
        </div>
        <div>
          <p></p>
        </div>
      </div>
      <div className="innerWrapper">
        <div
          // style={{ display: printView ? "none" : "inline-flex" }}
          className="floorTab"
        >
          <ul>
            {rows &&
              Array.isArray(rows) && rows.map((floor, index) => {
                return (
                  <li key={index}>
                    <a
                      className={floor.id === activeTab ? "active " : ""}
                      onClick={() => activeFloorDetails(floor?.id)}
                    >
                      {floor.floorName}

                      {Array.isArray(floorData?.devices) &&
                        floorData.devices
                          .filter(
                            (device) =>
                              (device.deviceId === 11 ||
                                device.deviceId === 14) &&
                              device.state === 3
                          )
                          .map((device) => device.deviceId).length > 0 ? (
                        <span>
                          <img
                            src={alertIcon}
                            className="alerticon"
                            alt="img"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {(Array.isArray(floorData?.devices)
                        ? floorData.devices
                        : []
                      )
                        .filter((device) => device.state === 2)
                        .map((device) => device.deviceId).length > 0 ? (
                        <span className="constructionImg">
                          <img
                            src={construction}
                            className="construction"
                            alt="img"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                );
              })}
          </ul>
          <div>
            <button
              className={`resetBtn`}
              style={{ "marginRight": "10px" }}
              onClick={onClickResetDmp}
            >
              Confirm Repair
            </button>
            <button
              className={`resetBtn`}
              onClick={() => {
                const isBlinkTextVisible =
                  (Array.isArray(floorData?.devices) ? floorData.devices : [])
                    .filter((device) => device.state === 3)
                    .map((device) => device.deviceId).length > 0;

                if (isBlinkTextVisible) {
                  setOverRideDeviceModal(true);
                  setClearAlarmsTriggered(true);
                  setOverrideDeviceHeading("Clear Alarms");
                }
              }}
            >
              Clear Alarms
            </button>
          </div>
        </div>
        <div className="bottom_wrapper">
          <div className="map_wrp_inout">
            <div
              id="printFloorMap"
              className={
                // ${activeTab == 1 ? "mapOne" : "mapTwo"}
                `floorOne  ${printView ? "forPrintView" : ""}
              ${(Array.isArray(floorData?.devices)
                  ? floorData.devices
                  : []
                ).filter((device) => device.state === 3).length > 0
                  ? "specialClass"
                  : ""
                }
            `
              }
              ref={ref}
            >
              {!isLoading ? (
                <img src={memoizedImage} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <div className={"showFloor"}>{floorDetails?.floorName}</div>
              {activeFloorPRintData && <div>{floorDetails?.floorName}</div>}
              {!isLoading &&
                floorData &&
                (Array.isArray(floorData?.devices)
                  ? floorData.devices
                  : []
                ).map((item, index) => {
                  const isHovered =
                    hoveredDeviceId !== null &&
                    hoveredDeviceId !== "" &&
                    hoveredDeviceId === item.deviceId;
                  return (
                    <>
                      <Tooltip
                        title={
                          // item.deviceType == "1" && item.state === 2 ? (
                          //   <ImageTooltipContent />
                          // ) :
                          item.deviceType == "4" && item.state === 2 ? (
                            <ImageTooltipContent />
                          ) : item.deviceType == "1" && item.state === 3 ? (
                            "Capturing"
                          ) : item.deviceType == "4" && item.state === 3 ? (
                            "Activated"
                          ) : (
                            ""
                          )
                        }
                        arrow
                        placement="top"
                        open={(item.deviceType == "1" || (item.deviceType == "4" &&  (item.state === 2 || item.state === 3)))
                        }
                        classes={{
                          tooltip: "customTooltip",
                          arrow: "customArrow",
                        }}
                      >
                        <button
                          x-data={"print"}
                          id={`trigger-${item.deviceId}`}
                          className={`signBtn ${item.deviceType == "1"
                              ? "camerBtn"
                              : item.deviceType == "2"
                                ? "commonLight"
                                : item.deviceType == "3"
                                  ? "audioIcon"
                                  : item.deviceType == "4"
                                    ? "pushBtn"
                                    : ""
                            } ${item.deviceType == "1" && item.state === 2
                              ? "cameraBroken"
                              : item.deviceType == "4" && item.state === 2
                                ? "pushBroken"
                                : ""
                            } five ${ids === item.deviceId ? "active" : ""} 
                          
                          ${item.state === 3
                              ?  (item.deviceType == "2" ||
                                  item.deviceType == "3") &&
                                  isDrillSpkAndStrobe &&
                                  printView &&
                                  (item.deviceType == "1" ||
                                    item.deviceType == "4") &&
                                  item.state !== 3
                                  ? "disnone"
                                  : "activated disnone"
                              : "disnone"
                            }
                        `}
                          style={{
                            position: "absolute",
                            // transform: `rotate(${item?.rotate}deg)`,
                            left: `${item?.cssXCoordinate}%`,
                            top: `${item?.cssYCoordinate}%`,
                            display: `
                        ${isDeviceVisible(
                              (Array.isArray(floorData?.devices)
                                ? floorData.devices
                                : []
                              ).filter((x) => x.deviceId === item.deviceId)[0]
                                ?.state
                            )
                                ? "block"
                                : "none"
                              }
                          `,
                          }}
                          // onClick={() => setHoveredDeviceId(item.deviceId)}
                          onClick={() => {
                            if (item.state === 3) {
                              setHoveredDeviceId((prevDeviceId) =>
                                prevDeviceId === item.deviceId
                                  ? null
                                  : item.deviceId
                              );
                              setDeviceOverrideId(item);
                            }
                          }}
                        >
                          {/* {item.deviceType == "3" && item.deviceType == "3" && (
                            <span></span>
                          )} */}
                        </button>
                      </Tooltip>
                      {/*   */}
                      {isHovered && (
                        <Tooltip
                          placement="top"
                          open={hovering}
                          classes={{
                            tooltip: "customTooltip",
                            arrow: "customArrow",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: `${item?.cssXCoordinate}%`,
                              top: `${item.deviceType == "1" && item.state === 3
                                  ? item?.cssYCoordinate - 14
                                  : item.deviceType == "4" && item.state === 3
                                    ? item?.cssYCoordinate - 14
                                    : item?.cssYCoordinate - 6
                                }%`,
                            }}
                            className="customTooltip overRideTooltip"
                          >
                            <button
                              className="deactiveTooltipbtn"
                              onClick={() => setOverRideDeviceModal(true)}
                            >
                              <img
                                src={deactivateIcon}
                                className="deactiveTooltipimg"
                              />
                              Deactivate
                            </button>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
          <Footer floorMapRef={ref} currentFloor={currentFloor} />
        </div>
      </div>
      <OverrideDevice
        overRideDeviceModal={overRideDeviceModal}
        setOverRideDeviceModal={setOverRideDeviceModal}
        successVerified={successVerified}
        setSuccessVerified={setSuccessVerified}
        setHoveredDeviceId={setHoveredDeviceId}
        heading={overrideDeviceHeading}
        // setIsdrillSpkAndStrobe={setIsdrillSpkAndStrobe}
      />
      {/* Dont remove this code releated to audio */}
      {/* {shouldPlayAlarm && (
              <div>
                <iframe
                  src={silence}
                  allow="autoplay"
                  id="audio"
                  style={{ display: "none" }}
                ></iframe>
                <audio id="myAudio" src={memoizedAlarmSound} autoPlay="allow"></audio>
              </div>
            )} */}
    </div>
  ) : (
    ""
  );
};

export default FloorMap;
