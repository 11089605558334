import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    FormControl,
    InputLabel,
    Box,
    Modal,
    NativeSelect,
    FormHelperText
} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import closeIcon from '../../assets/images/closeicon.svg'
import { fieldUser } from "./FieldJson"
import { Validation } from '../../Validation/Validation';
import checkImg from "../../assets/images/check_circle.svg";
import { AddUserAction, EditUserAction } from "../../Actions/AddEditUserAction";
import './mustyle.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};
const AddEditUser = (props) => {
    const userState = useSelector((state) => state.usersState);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        // password: '',
        roleAssignment: '',
        status: '',

    });
    const dispatch = useDispatch();
    const [formError, setFormError] = useState("");
    // const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const { setOpen, open, actionButton, setActionButton, editData } = props
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const requiredFields = ["firstName", "lastName", "email", "roleAssignment", "status"];

    useEffect(() => {
        if(!userState.isLoading && userState.data !== null) {
            setSuccessMessage(true)
        } else if(!userState.isLoading && userState.error !== "") {
            if(actionButton === 'edit') {
                const errorData = userState.error.data;
                setFormError((prevState) => ({
                    ...prevState,
                    email: errorData || "",
                    firstName: errorData.firstName || "",
                    lastName: errorData.lastName || "",
                    roleAssignment: errorData.roleAssignment || "",
                    status: errorData.status || ""
                }));
            } else {
                if (userState.error && userState.error.status === 409) {
                    setFormError({ email: "User with the same email already exists" });
                } else {
                    console.error("Error making request:", userState.error.data.Status);
                }
            }
        }
    }, [userState])

    const handleValidation = () => {
        let isValid = true;
        let errors = {};
    
        requiredFields.forEach((field) => {
            if (actionButton === "add" && field === "status") return;
            if (Validation.empty(formData[field])) {
                isValid = false;
                errors[field] = `Please enter ${field}`;
            }
        });

        if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            isValid = false;
            errors.email = "Please enter a valid email address";
        }
    
        if (!isValid) {
            setFormError(errors);
        } else {
            console.log("Validation passed, clearing errors.");
            setFormError({});
        }
    
        return isValid;
    };
    
    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                // password: formData.password,
                password: "string",
                telephone: "string",
                gender: "string",
                tenantId: 712,
                tenantName: "string",
                title: "None",
                roleName: formData.roleAssignment,
                note: "Notes 1",
                userRoles: [formData.roleAssignment],
                userStatus: "PENDING",
            };
            dispatch(AddUserAction(formDataToSend));
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("")
        setSuccessMessage(false)
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            // password: '',
            roleAssignment: '',
            status: '',
        });
    };
    const handleEdit = async () => {
        try {
            const formDataToSend = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                // password: formData.password,
                password: "string",
                telephone: "string",
                gender: "string",
                tenantId: 712,
                tenantName: "string",
                title: "None",
                roleName: formData.roleAssignment,
                note: "Notes 1",
                userRoles: [formData.roleAssignment],
                userStatus: formData.status,
            };
            dispatch(EditUserAction(formDataToSend, editData.userid));
        } catch (error) {
            console.error("Error updating device:", error?.response?.data);
            if (error?.response && error?.response?.data) {
                const errorData = error?.response?.data;
                setFormError((prevState) => ({
                    ...prevState,
                    email: errorData || "",
                    firstName: errorData.firstName || "",
                    lastName: errorData.lastName || "",
                    roleAssignment: errorData.roleAssignment || "",
                    status: errorData.status || ""
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        if (actionButton === 'add') {
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };

    useEffect(() => {
        if (actionButton === 'edit' && editData) {
            setFormData({
                firstName: editData.name.split(" ")[0],
                lastName: editData.name.split(" ")[1],
                email: editData.emailaddress,
                roleAssignment: editData.role,
                status: editData.status,
            });
        } else {
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                // password: '',
                roleAssignment: '',
                status: '',
            });
        }
    }, [actionButton, editData]);


    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >
        <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit New User" : "Create New User"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle  addDeviceMiddle login-form addManageUser">
                    {!successMessage ? 
                        <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                            {fieldUser.map((data, index) => {
                                return (
                                    <>
                                        {
                                            data.type == "input" ?
                                                <TextField
                                                    key={index}
                                                    id={data.id}
                                                    name={data.name}
                                                    label={data.label}
                                                    variant="outlined"
                                                    // type={data.inputType === "password" && showPassword ? "text" : data.inputType}
                                                    onChange={handleInputChange}
                                                    value={formData[data.name] || ''}
                                                    error={!!formError[data.name]}
                                                    className="innerFields"
                                                    helperText={formError[data.name] || data.helperText}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            transform: 'inherit',
                                                        },
                                                    }}
                                                    // InputProps={{
                                                    //     style: { borderRadius: '4px' },
                                                    //     ...(data.inputType === "password" && {
                                                    //         endAdornment: (
                                                    //             <InputAdornment position="end">
                                                    //                 <IconButton onClick={togglePasswordVisibility} edge="end">
                                                    //                     {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    //                 </IconButton>
                                                    //             </InputAdornment>
                                                    //         ),
                                                    //     }),
                                                    // }}
                                                />
                                                : data.type === "select" && (actionButton !== "add" || data.name !== "status") ?
                                                    <FormControl fullWidth className="innerFields ">
                                                        <InputLabel variant="standard" htmlFor={data.id}
                                                            shrink={false}
                                                        >
                                                            {data.label}
                                                        </InputLabel>
                                                        <NativeSelect
                                                            id={data.id}
                                                            name={data.name}
                                                            value={formData[data.name] || ''}
                                                            onChange={handleInputChange}
                                                            style={{marginTop: "4px"}}
                                                        >
                                                            <option value="" disabled>
                                                                Select a {data.placeHolder}
                                                                </option>
                                                            {data.data &&
                                                                data.data.map((role) => (
                                                                    <option key={role.value} value={role.value}>
                                                                        {role.label}
                                                                    </option>
                                                                ))}

                                                        </NativeSelect>

                                                        {/* Add FormHelperText */}
                                                        <FormHelperText>
                                                            {formError[data.name]}
                                                        </FormHelperText>
                                                    </FormControl>
                                                    : " "
                                        }
                                    </>
                                )
                            })}
                        </Box> :     
                        <div className="emailsent-wrapper manageUser_details">
                          
                            <img src={checkImg} alt="img" />
                            <h2>Success</h2>
                            <p>User has { actionButton == "add" ? "created" : "updated"} successfully.</p>
                            <span>Return to <a className="btnSubmit"  onClick={() => handleClose()}>manage User</a></span>
                        </div>
                    }
                </div>
                <div className="Modal_footer adddeviceFooter">
                    {/* Buttons */}
                   {!successMessage && <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" className="cancelBtn" onClick={() => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={(e) => handleSubmit(e)}>
                            Save
                        </Button>
                    </Box>}
                </div>
            </Box> 
        </Modal>
    
    );
};

export default AddEditUser;
