import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Button,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Typography,
    Modal,
    NativeSelect,
    FormHelperText,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Chip,
    Autocomplete,
    ListItemText
} from '@mui/material';
import {
    LocalizationProvider,
    DatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import closeIcon from '../../assets/images/closeicon.svg'
import { field } from "./FieldJson"
import { Validation } from '../../Validation/Validation';
import mqtt from "mqtt";
import { Container, Row, Col } from 'react-bootstrap';
import errorSign from '../../assets/images/errorSign.svg'
import checkImg from "../../assets/images/check_circle.svg";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import './style.scss'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};
const AddEditDevice = (props) => {
    const [formData, setFormData] = useState({
        type: {},
        name: '',
        deviceMake: '',
        cameraType: '',
        streamUrl: '',
        videoLength: '',
        status: 0,
        serialNumber: '',
        dateInstalled: null,
        macAddress: '',
        ipAddress: '',
        topicName: '',
        triggers: [0],
    });
    const [checked, setChecked] = useState(false);
    const [formError, setFormError] = useState("");
    const [renderDeviceType, setRenderDeviceType] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);
    const [testConnectionStatus, setTestConnectionStatus] = useState("");
    const [streamUrlNew, setStreamUrlNew] = useState("");
    const [deviceType, setDeviceType] = useState([]);
    const [statusType, setStatusType] = useState([]);
    const [triggerOption, setTriggerOption] = useState([]);
    const [mapWithNiagraList, setMapWithNiagraList] = useState([])
    const [selectedTopic, setSelectedTopic] = useState("");
    const { setOpen, open, actionButton, setActionButton, editData, rows } = props
    const mqttClient = useRef(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === "streamUrl") {
            setStreamUrlNew(value);
        }
    };

    const handleDeviceTypeChange = (e) => {
        const selectedType = deviceType.find((type) => type.id === Number(e.target.value));
        setRenderDeviceType(selectedType)
        setFormData((prevState) => ({
            ...prevState,
            type: selectedType || {}
        }));
    };
    const handleStatusTypeChange = (e) => {
        const selectedStatus = statusType.find((status) => status.id === Number(e.target.value));
        setFormData((prevState) => ({
            ...prevState,
            status: selectedStatus ? selectedStatus.id : 0
        }));
    };
    // Date change handler
    const handleDateChange = (newValue) => {
        setFormData(prevState => ({
            ...prevState,
            dateInstalled: newValue ? dayjs(newValue) : null
        }));
    };
    const handleTriggersId = (event, value) => {
        const selectedIds = value.map((item) => item.id);
        setFormData((prevState) => ({
            ...prevState,
            triggers: selectedIds
        }));
    };

    const handleValidation = () => {
        let isValid = true;
        let errors = {};
    
        console.log("formData before validation:", rows, formData.name);
    
        const existingDeviceNames = rows.map(device => device.name);
    
        // Validate device type
        if (!formData.type?.id) {
            errors.type = "Please enter a Device type";
            isValid = false;
        }
    
        // Required fields (excluding topicName if actionButton is "add")
        const requiredFields = ["name", "deviceMake", "serialNumber", "dateInstalled", "macAddress", "ipAddress"];
        if (actionButton !== "add") requiredFields.push("topicName"); // Only validate topicName if NOT adding a device
    
        requiredFields.forEach(field => {
            if (!formData[field]) {
                errors[field] = `Please enter ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`;
                isValid = false;
            }
        });
    
        // Ensure unique device name
        if (existingDeviceNames.includes(formData.name)) {
            errors.name = "Device name must be unique. This name is already taken.";
            isValid = false;
        }
    
        // Validate dateInstalled
        if (!formData.dateInstalled?.isValid()) {
            errors.dateInstalled = "Please enter a valid installation date";
            isValid = false;
        }
    
        // Camera-specific validation
        if (formData.type?.name === "Camera") {
            console.log("Validating Camera-specific fields:");
    
            if (!formData.cameraType) {
                errors.cameraType = "Please enter a Camera type";
                isValid = false;
            }
    
            if (!/^(https?|rtsp):\/\/.+/.test(formData.streamUrl || "")) {
                errors.streamUrl = "Please enter a valid stream URL";
                isValid = false;
            }
    
            if (!Number(formData.videoLength) || formData.videoLength <= 0) {
                errors.videoLength = "Please enter a valid video length (positive number)";
                isValid = false;
            }
        }
    
        // Validate IP Address
        const ipRegex = {
            v4: /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/,
            v6: /^([0-9a-fA-F]{1,4}:){1,7}[0-9a-fA-F]{1,4}$/
        };
    
        if (!ipRegex.v4.test(formData.ipAddress) && !ipRegex.v6.test(formData.ipAddress || "")) {
            errors.ipAddress = "Please enter a valid IPv4 or IPv6 address";
            isValid = false;
        }
    
        // Validate MAC Address
        if (!/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(formData.macAddress || "")) {
            errors.macAddress = "Please enter a valid MAC address";
            isValid = false;
        }
    
        // Log final validation errors
        console.log("Final Validation Errors:", errors);
    
        // Ensure topicName is NOT in errors when adding a device
        if (actionButton === "add") {
            delete errors.topicName;
        }
    
        setFormError(errors);
        
        return isValid;
    };
    
    

    // Submit handler
    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const formDataToSend = {
                name: formData.name,
                cssXCoordinate: 0,
                cssYCoordinate: 0,
                type: formData.type,
                cameraType: formData.cameraType,
                streamUrl: formData.streamUrl,
                videoLength: formData.videoLength,
                deviceLocationName: "",
                deviceLocationId: null,
                triggeredByDeviceId: [0],
                deleted: false,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                createdBy: decodedToken.userGuid,
                modifiedBy: decodedToken.userGuid,
                triggeredBy: "0",
                deviceMake: formData.deviceMake,
                status: formData.status,
                serialNumber: formData.serialNumber,
                installed: formData.dateInstalled,
                macAddress: formData.macAddress,
                ipAddress: formData.ipAddress,
                topicName: "niagara/device/status/01",
                floorId: null
            };
            const url = `${process.env.REACT_APP_DEVICE_API_URL}/device/addDevice`;
            // const response = await axios.get(url);
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json',  // Optional: Ensure content type is JSON
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 201) {
                setSuccessMessage(true)
            }
        } catch (error) {
            console.error("Error making request:", error);
            // setFormError("Invalid credentials");
            // setShowError(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("")
        setSuccessMessage(false)
        setTestConnectionStatus("")
        setChecked(false)
        setFormData({
            name: '',
            type: {},
            deviceMake: '',
            cameraType: '',
            streamUrl: '',
            videoLength: '',
            status: 0,
            serialNumber: '',
            dateInstalled: null,
            macAddress: '',
            ipAddress: '',
            topicName: '',
            triggers: [0]
        });
    };

    const handleEdit = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const formDataToSend = {
                id: editData.id,
                name: formData.name,
                type: formData.type,
                cssXCoordinate: editData.cssXCoordinate,
                cssYCoordinate: editData.cssYCoordinate,
                cameraType: formData.cameraType,
                streamUrl: formData.streamUrl,
                videoLength: Number(formData.videoLength),
                deviceLocationName: "string",
                deviceLocationId: editData.deviceLocationId,
                triggeredByDeviceId: [0],
                deleted: false,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                createdBy: editData.createdBy,
                modifiedBy: decodedToken.userGuid,
                triggeredBy: formData.triggers.join(','),
                deviceMake: formData.deviceMake,
                status: formData.status,
                serialNumber: formData.serialNumber,
                installed: formData.dateInstalled,
                macAddress: formData.macAddress,
                ipAddress: formData.ipAddress,
                topicName: formData.topicName,
                // floorId: editData.floorId
            };

            const response = await axios.put(`${process.env.REACT_APP_DEVICE_API_URL}/device/update/${editData.id}`, formDataToSend, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, }
            });

            if (response.status === 200) {
                setSuccessMessage(true)
            }
        } catch (error) {
            console.error("Error updating device:", error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        if (actionButton === 'add') {
            console.error("Error updating device:");
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };
    // Listing APi 
    const fetchDeviceType = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${process.env.REACT_APP_DEVICE_API_URL}/deviceType/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setDeviceType(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    // Listing APi 
    const fetchStatusType = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${process.env.REACT_APP_DEVICE_API_URL}/states/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setStatusType(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        // Call the async function
        fetchDeviceType();
        fetchStatusType();
    }, []);

    useEffect(() => {
        if (actionButton === 'edit' && editData) {
            const dateInstalledValue = editData.installed
                ? dayjs(editData.installed, 'YYYY-MM-DD')
                : null;
            setFormData({
                name: editData.name,
                type: editData.type,
                deviceMake: editData.deviceMake,
                status: editData.status,
                serialNumber: editData.serialNumber,
                dateInstalled: dateInstalledValue,
                macAddress: editData.macAddress,
                ipAddress: editData.ipAddress,
                topicName: editData.topicName,
                cameraType: editData.cameraType,
                streamUrl: editData.streamUrl,
                videoLength: editData.videoLength,
                cssXCoordinate: editData.cssXCoordinate,
                cssYCoordinate: editData.cssYCoordinate,
                triggers: editData.triggeredBy
            });
        } else {
            setFormData({
                name: "",
                deviceMake: "",
                status: 0,
                type: {},
                deviceMake: '',
                cameraType: '',
                streamUrl: '',
                serialNumber: "",
                dateInstalled: null,
                macAddress: "",
                ipAddress: "",
                topicName: "",
                triggers: []
            });
        }
    }, [actionButton, editData]);

    const handleTestConnection = async (e) => {
        e.preventDefault();

        const url = streamUrlNew; // Replace with your endpoint
        console.log(url, "Testing connection...");

        // Validate streamUrl
        if (!url || !/^https?:\/\/.+/.test(url)) {
            setTestConnectionStatus("error");
            console.error("Invalid URL: Please enter a valid stream URL.");
            return;
        }

        // Proceed with the connection test if URL is valid
        try {
            const response = await fetch(url, { method: "HEAD" });
            if (response.ok) {
                setTestConnectionStatus("success");
                console.log("Connection successful.");
            } else {
                setTestConnectionStatus("error");
                console.error("Connection failed:", response.statusText);
            }
        } catch (error) {
            setTestConnectionStatus("error");
            console.error("Error occurred during the connection test:", error);
        }
    };
    useEffect(() => {
        if (actionButton === "edit") {
            const fetchData = async () => {
                try {
                    const token = JSON.parse(localStorage.getItem("token"));
                    const url = `${process.env.REACT_APP_DEVICE_API_URL}/device/type/4`;
                    const response = await axios({
                        method: 'GET',
                        url: url,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.status === 200) {
                        setTriggerOption(response.data);
                        // setRows(response.data);
                        // setIsLoading(false);
                    }
                } catch (error) {
                    console.error("Error making request:", error);
                    // setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [actionButton]);


    const toggleCheckbox = () => {
        setChecked((prevChecked) => !prevChecked);
    
        mqttClient.current = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
            username: process.env.REACT_APP_MQTT_USERNAME,
            password: process.env.REACT_APP_MQTT_PASSWORD,
        });
    
        mqttClient.current.on("connect", function () {
            window.mqttConnected = true;
            console.log("Connected to MQTT broker");
            mqttClient.current.subscribe("niagara/#");
        });
    
        mqttClient.current.on("message", function (topic, message) {
            try {
                let decodedMessage = new TextDecoder("utf-8").decode(message).trim();
                if (decodedMessage.startsWith("{") && decodedMessage.endsWith("}")) {
                    const parsedMessage = JSON.parse(decodedMessage);
                    setMapWithNiagraList((prevState) => ({
                        ...prevState,
                        ...parsedMessage,
                    }));
                } else {
                    console.error("Invalid JSON format received:", decodedMessage);
                }
            } catch (error) {
                console.error("Error parsing MQTT message:", error);
            }
        });
        
    };
    const handleSelect = (event, selectedDevice) => {
        if (selectedDevice) {
            const topic = mapWithNiagraList[selectedDevice]?.topic || "";
            console.log(topic, "mapWithNiagraList[selectedDevice]?.topic");
                setFormData((prevData) => ({
                ...prevData,
                topicName: topic,
            }));
        }
    };
    const options = mapWithNiagraList && Object.keys(mapWithNiagraList).length > 0
    ? Object.keys(mapWithNiagraList) 
    : [];
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit Device Management" : "Add Device Management"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle  addDeviceMiddle login-form">
                    {!successMessage ?
                        <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                            <Row>
                                { (actionButton == "edit" && formData.type.id == 4) && (<Col xl={12} lg={12} md={12} sm={12}>
                                    <div className="mapNigraCheck">
                                        <label>Map with Niagara</label>
                                        <Checkbox
                                            checked={checked}
                                            onChange={toggleCheckbox}
                                            className="checkboxNiagra"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                    </div>
                                </Col>)}
                                
                                {checked && actionButton == "edit" ?
                                    <Col xl={6} lg={6} md={6} sm={6}>
                                        <div className="deviceListNaigra">
                                            <Autocomplete
                                                disablePortal
                                                options={options}
                                                className="innerFields addDevices addnigraDevice"
                                                onChange={handleSelect} // Handle selection
                                                renderInput={(params) => <TextField {...params} label="Niagara Devices" />}
                                            />
                                        </div> </Col> : ''}
                                
                                {field.map((data, index) => {
                                    return (
                                        <>
                                            {data.type === "Camera" ? (
                                                (renderDeviceType.name === "Camera" && renderDeviceType.name !== "") || formData.type ?.id === 1 ? (
                                                    <Col xl={6} lg={6} md={6} sm={6} className="reletiveWrapper" key={index}>
                                                        <TextField
                                                            id={data.id}
                                                            name={data.name}
                                                            label={data.label}
                                                            variant="outlined"
                                                            onChange={handleInputChange}
                                                            value={formData[data.name] || ''}
                                                            error={!!formError[data.name]}
                                                            className="innerFields addDevices"
                                                            helperText={formError[data.name] || data.helperText}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                style: { transform: 'inherit' },
                                                            }}
                                                            InputProps={{
                                                                style: { borderRadius: '4px' },
                                                            }}
                                                        />
                                                        {data.id === "streamUrl" && (
                                                            <button
                                                                className={`testConnection ${
                                                                    testConnectionStatus === "success"
                                                                        ? "scs"
                                                                        : testConnectionStatus === "error"
                                                                            ? "err"
                                                                            : ""
                                                                    }`}
                                                                onClick={handleTestConnection}
                                                            >
                                                                Test Connection
                                                        </button>
                                                        )}
                                                    </Col>
                                                ) : null
                                        ) : data.type === "input" ? (
                                                    actionButton === "edit" && data.name === "topicName" ? (
                                                        <Col xl={6} lg={6} md={6} sm={6} key={index}>
                                                            <TextField
                                                                id={data.id}
                                                                name={data.name}
                                                                label={data.label}
                                                                variant="outlined"
                                                                onChange={(e) => {
                                                                    let baseValue = e.target.value.replace(`/${editData.id}`, '').trim();
                                                                    handleInputChange({ target: { name: data.name, value: `${baseValue}/${editData.id}` } });
                                                                }}
                                                                value={
                                                                    checked 
                                                                        ? selectedTopic || formData[data.name] // If checked, use selectedTopic or keep the original value
                                                                        : formData[data.name]
                                                                            ? formData[data.name].endsWith(`/${editData.id}`)
                                                                                ? formData[data.name]
                                                                                : `${formData[data.name]}/${editData.id}`
                                                                            : `/${editData.id}`
                                                                }
                                                                
                                                                error={!!formError[data.name]}
                                                                className="innerFields addDevices"
                                                                helperText={formError[data.name] || data.helperText}
                                                                disabled={checked}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    style: { transform: 'inherit' },
                                                                }}
                                                                InputProps={{
                                                                    style: { borderRadius: '4px' },
                                                                }}
                                                            />

                                                        </Col>
                                                    ) : data.name !== "topicName" ? (
                                                        <Col xl={6} lg={6} md={6} sm={6} key={index}>
                                                            <TextField
                                                                id={data.id}
                                                                name={data.name}
                                                                label={data.label}
                                                                variant="outlined"
                                                                onChange={handleInputChange}
                                                                value={formData[data.name] || ''}
                                                                error={!!formError[data.name]}
                                                                className="innerFields addDevices"
                                                                helperText={formError[data.name] || data.helperText}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    style: { transform: 'inherit' },
                                                                }}
                                                                InputProps={{
                                                                    style: { borderRadius: '4px' },

                                                                }}
                                                            />
                                                        </Col>
                                                    ) : null
                                                ) : data.type === "select" ? (
                                                    <Col xl={6} lg={6} md={6} sm={6} key={index}>
                                                        <FormControl fullWidth className="innerFields addDevices">
                                                            <InputLabel variant="standard" htmlFor={data.id} shrink={false}>
                                                                {data.label}
                                                            </InputLabel>
                                                            <NativeSelect
                                                                id={data.id}
                                                                name={data.name}
                                                                value={data.name === "status" ? formData.status || '' : formData.type ?.id || ''}
                                                                onChange={(e) =>
                                                                    data.name === "status" ? handleStatusTypeChange(e) : handleDeviceTypeChange(e)
                                                                }
                                                                style={{marginTop: "4px"}}
                                                            >
                                                                <option value="" disabled>
                                                                    Select {data.label}
                                                                </option>
                                                                {(data.name === "status" ? statusType : deviceType)
                                                                    ?.sort((a, b) => a.name.localeCompare(b.name))
                                                                        .map((item) => (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                            </NativeSelect>
                                                            <FormHelperText>{formError.type}</FormHelperText>
                                                        </FormControl>
                                                    </Col>
                                                ) : data.type === "multiSelect" && actionButton === "edit" ? (
                                                    <Col xl={6} lg={6} md={6} sm={6} key={index}>
                                                        <FormControl fullWidth className="innerFields addDevices triggerFeild">
                                                            <Autocomplete
                                                                multiple
                                                                options={triggerOption}
                                                                disableCloseOnSelect
                                                                getOptionLabel={(option) => option.name}
                                                                value={triggerOption.filter((option) => formData.triggers.includes(option.id))}
                                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                renderOption={(props, option, { selected }) => (
                                                                    <li {...props}>
                                                                        <Checkbox checked={selected} style={{ marginRight: 8 }} />
                                                                        <ListItemText primary={option.name} />
                                                                    </li>
                                                                )}
                                                                onChange={(event, value) => handleTriggersId(event, value)}
                                                                renderInput={(params) => <TextField {...params} variant="outlined" label="Trigger By" />}
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                ) : data.type === "datePicker" ? (
                                                    <Col xl={6} lg={6} md={6} sm={6} key={index}>
                                                        <div className="innerFields addDevices">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    label={data.label}
                                                                    value={formData.dateInstalled}
                                                                    onChange={handleDateChange}
                                                                    renderInput={(params) => (
                                                                        <FormControl fullWidth variant="outlined">
                                                                            <InputLabel htmlFor={params.inputProps.id} shrink={false}>
                                                                                {data.label}
                                                                            </InputLabel>
                                                                            <TextField
                                                                                {...params}
                                                                                fullWidth
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        error: !!formError.dateInstalled,
                                                                                        helperText: formError.dateInstalled,
                                                                                    },
                                                                                }}
                                                                                variant="outlined"
                                                                                InputLabelProps={{
                                                                                    shrink: false,
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                                {formError.dateInstalled && <p className="customerror">Please enter installation date</p>}
                                                            </LocalizationProvider>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                        </>

                                    )
                                })}
                            </Row>
                        </Box>
                        : <div className="emailsent-wrapper manageUser_details">
                            <img src={checkImg} alt="img" />
                            <h2>Success</h2>
                            <p>Device has {actionButton == "add" ? "created" : "updated"} successfully.</p>
                        </div>
                    }
                </div>
                {/* <div className="error_wraper">
                    <img src={errorSign} alt="img" />
                    {formError}
                </div> */}
                <div className="Modal_footer adddeviceFooter">
                    {/* Buttons */}
                    {!successMessage && <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" className="cancelBtn" onClick={(e) => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={(e) => handleSubmit(e)}>
                            Save
                        </Button>
                    </Box>}
                </div>
            </Box>
        </Modal>

    );
};

export default AddEditDevice;
