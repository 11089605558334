const initialState = {
    progress: 0, // Initial progress
  };
  
  const ProgressReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_PROGRESS":
        console.log("Redux: Progress updated to", action.payload);
        return { ...state, progress: action.payload };
      case "RESET_PROGRESS":
        return { ...state, progress: 0 };
      default:
        return state;
    }
  };
  
  export default ProgressReducer;