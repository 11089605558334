import React, { useState, useEffect } from "react";
import {
  styled,
  Switch,
  Stack,
  FormGroup,
  Snackbar,
  Button,
} from "@mui/material";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux"

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: "flex",
  borderRadius: 16,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(8, 109, 171, 1)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
    borderRadius: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
  },
}));

const defaultChannels = [
  {
    notificationName: "Activate All Strobe and Speakers",
    isActive: false,
    code: "isStrobeSpeakersEnabled",
  },
  {
    notificationName: "Camera Footage",
    isActive: false,
    code: "isCameraFootageEnabled",
  },
  {
    notificationName: "Email Functionality",
    isActive: false,
    code: "isEmailFunctionalityEnabled",
  },
  {
    notificationName: "Notify Monitoring Partner",
    isActive: false,
    isDisabled: true,
    code: "isNotify911Enabled",
  },
];

const DrillModeSetting = () => {
  const [notificationChannel, setNotificationChannel] =
    useState(defaultChannels);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isModified, setIsModified] = useState(false);
  const isLockdown = localStorage.getItem("isLockdownActive");
  const token = JSON.parse(localStorage.getItem("token"));
  const decodedToken = jwtDecode(token);
  const enableSnackbar = useSelector((state) => state.snackbar.enableSnackbar);

  useEffect(() => {
    setUserRole(decodedToken.userRole);
  }, []);
  const fetchNotificationSettings = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const settingsArray = response.data.allGlobalSettings || [];
      // if (!Array.isArray(settingsArray)) {
      //   console.error('Expected settings to be an array but got:', typeof settingsArray);
      //   return;
      // }

      // // Save the full response array for later use.
      setOriginalData(settingsArray);

      const updatedChannels =
        settingsArray &&
        defaultChannels.map((channel) => {
          const setting = settingsArray.find(
            (item) => item.code === channel.code
          );
          return {
            ...channel,
            isActive: setting ? setting.value === "1" : false,
          };
        });

      setNotificationChannel(updatedChannels);
    } catch (error) {
      console.error("Error fetching notification settings:", error);
    }
  };
  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const handleToggle = (index) => {
    const updated = notificationChannel.map((channel, i) =>
      i === index ? { ...channel, isActive: !channel.isActive } : channel
    );
    setNotificationChannel(updated);
    setIsModified(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("token"));

      const payload = notificationChannel.map((channel) => ({
        code: channel.code,
        value: channel.isActive ? "1" : "0",
      }));

      await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/updateGlobalSettingCode`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage("Setting Saved Successfully");
      setOpen(true);
      setIsModified(false);
      fetchNotificationSettings();
    } catch (error) {
      console.error("Error updating settings:", error);
      setMessage("Error updating settings");
      setOpen(true);
    }
  };

  return (
    <div className="update_pass_mnWrapper">
      <div className="updatePassword_myacc">
        <div className="update_wrapper security_check_wrapper drill_check_wrapper">
          <div className="profile-form">
            {notificationChannel.map((data, index) => (
              <div className="securityChecks drillChecks" key={index}>
                <div className="sc_heading">
                  <p>{data.notificationName}</p>
                </div>
                <div className="sc_toggle drillTogles">
                  <FormGroup>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <AntSwitch
                        checked={data.isActive}
                        onChange={() => handleToggle(index)}
                        disabled={
                          data.isDisabled ||
                          !(
                            userRole === "SUPER_ADMIN" ||
                            userRole === "SUB_ADMIN"
                          ) ||
                          isLockdown === "true"
                        }
                        // disabled={data.isDisabled}
                      />
                    </Stack>
                  </FormGroup>
                </div>
              </div>
            ))}

            <div className="profile_wrapperbtn">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!isModified}
                  sx={{
                    backgroundColor: !isModified ? "#BFC0C9" : "#086DAB",
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  Save
                </Button>
              </div>
              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={5000}
                message={message}
                onClose={() => setOpen(false)}
                className="success drillSettingUpdated"
                sx={{
                  ...(enableSnackbar && { paddingTop: "45px" }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrillModeSetting;
