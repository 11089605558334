import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Button,
  NativeSelect,
  Snackbar,
  Alert,
  TextField,
  FormGroup,
  Stack,
  Switch,
  styled,
} from "@mui/material";
import axios from "axios";
import { prefrenceData } from "./FieldJson";
import CustomizeAudio from "./CustomizeAudio";
import "../MediaSettings/MediaSettings.scss";
import { useDispatch, useSelector } from "react-redux";

const MediaSettings = () => {
  const [formData, setFormData] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [savedData, setSavedData] = useState({});
  const [toggles, setToggles] = useState({
    playCount: false,
    timeDuration: false,
  });
  const [inputValues, setInputValues] = useState({
    playCount: "3", // Default value
    timeDuration: "3", // Default value
  });
  const [errors, setErrors] = useState({ playCount: "", timeDuration: "" });
  const [isAudioSaveEnabled, setIsAudioSaveEnabled] = useState(false);
  const dispatch = useDispatch();
  const enableSnackbar = useSelector((state) => state.snackbar.enableSnackbar);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: "flex",
    borderRadius: 16,
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "rgba(8, 109, 171, 1)",
          ...theme.applyStyles("dark", {
            backgroundColor: "rgba(8, 109, 171, 1)",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20,
      height: 20,
      borderRadius: 16,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));

  useEffect(() => {
    const fetchGlobalSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`
        );

        if (response.status === 200) {
          const globalSettings = response.data.allGlobalSettings;

          const videoLengthSetting = globalSettings.find(
            (setting) => setting.code === "videoLength"
          );
          const playSetting = globalSettings.find(
            (setting) => setting.code === "playSetting"
          );

          let playCountValue = "3";
          let timeDurationValue = "3";
          let playCountToggle = false;
          let timeDurationToggle = false;

          if (playSetting) {
            try {
              const parsedValue = JSON.parse(playSetting.value); // Parse the stringified JSON
              if (parsedValue.type === "0") {
                playCountValue = parsedValue.value;
                playCountToggle = true; // Enable playCount toggle
              } else if (parsedValue.type === "1") {
                timeDurationValue = parsedValue.value;
                timeDurationToggle = true; // Enable timeDuration toggle
              }
            } catch (error) {
              console.error("Error parsing playSetting value:", error);
            }
          }

          setFormData({
            videoLength: videoLengthSetting ? videoLengthSetting.value : "10",
          });

          setInputValues({
            playCount: playCountValue,
            timeDuration: timeDurationValue,
          });

          setToggles({
            playCount: playCountToggle,
            timeDuration: timeDurationToggle,
          });

          setSavedData({
            videoLength: videoLengthSetting ? videoLengthSetting.value : "10",
            playCount: playCountValue,
            timeDuration: timeDurationValue,
          });
        }
      } catch (error) {
        console.error("Error fetching global settings:", error);
      }
    };

    fetchGlobalSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      const videoChanged = updatedData.videoLength !== savedData.videoLength;
      setIsSaveEnabled(videoChanged);
      return updatedData;
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = [
      { code: "videoLength", value: formData.videoLength }, // Default value if not set
    ];

    if (toggles.playCount) {
      payload.push({
        code: "playSetting",
        value: JSON.stringify({ type: "0", value: inputValues.playCount }), // Stringify value
      });
    } else if (toggles.timeDuration) {
      payload.push({
        code: "playSetting",
        value: JSON.stringify({ type: "1", value: inputValues.timeDuration }), // Stringify value
      });
    }

    console.log("Modified Payload:", JSON.stringify(payload));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/updateGlobalSettingCode`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setShowSuccessSnackbar(true);
        setIsSaveEnabled(false);
        setIsAudioSaveEnabled(false);
        setSavedData({ ...formData, ...inputValues });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleToggle = (toggleName) => {
    setToggles((prevToggles) => {
      const newToggles = { playCount: false, timeDuration: false };
      newToggles[toggleName] = !prevToggles[toggleName];
      const hasToggleChanged =
        newToggles[toggleName] !== prevToggles[toggleName];

        setErrors({ playCount: "", timeDuration: "" });
        
      if (hasToggleChanged) {
        setIsAudioSaveEnabled(true); // Enable save button when toggling
      }
      return newToggles;
    });
    setInputValues((prevValues) => ({
      ...prevValues,
      [toggleName]: prevValues[toggleName] || "3",
    }));
    setErrors((prev) => ({ ...prev, [toggleName]: "" }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/[^0-9]/g, ""); // Only allow numbers

    setInputValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: newValue };

      let errorMsg = "";
      if (!newValue) {
        errorMsg = `${name === "playCount" ? "Play count" : "Time duration"} cannot be empty`;
      } else if (Number(newValue) === 0) {
        errorMsg = `${name === "playCount" ? "Play count" : "Time duration"} cannot be 0`;
      }  else if (name === "playCount" && Number(newValue) > 10) {
        errorMsg = "Play count cannot be greater than 10";
      } else if (name === "timeDuration" && Number(newValue) > 100) {
        errorMsg = "Time duration cannot be greater than 100";
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMsg,
      }));

      // Enable Audio Save only if values are modified AND no errors exist
      const hasChanged = Object.keys(updatedValues).some(
        (key) => updatedValues[key] !== savedData[key]
      );
      setIsAudioSaveEnabled(hasChanged && !errorMsg);

      return updatedValues;
    });
  };
  return (
    <div className="media_control_wrapper">
      <div className="media_wrapper">
        <div className="dropdown-wrapper">
          <Box component="form" noValidate autoComplete="off">
            {prefrenceData.map((preference) => (
              <FormControl
                fullWidth
                className="innerFields"
                key={preference.id}
                style={{
                  marginTop: "3%",
                  width: "220px",
                }}
              >
                <div
                  variant="standard"
                  htmlFor={preference.id}
                  className="sub-heading"
                >
                  {preference.label}
                </div>
                <NativeSelect
                  id={preference.id}
                  name={preference.name}
                  value={formData[preference.name] || ""}
                  onChange={handleChange}
                  style={{
                    border: "1px solid #4E4E4E",
                    borderRadius: "6px",
                    fontFamily: "Barlow Semi Condensed",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0.0275em",
                    textAlign: "left",
                    bottom: "10px",
                    position: "relative",
                  }}
                  className="custon-select"
                  disableUnderline="true"
                >
                  <option value="" disabled>
                    Select an {preference.label}
                  </option>
                  {preference.data.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            ))}
          </Box>
        </div>
        <div className="btn-wrapper">
          <Button
            id="videoSave"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isSaveEnabled}
            className="save"
          >
            Save
          </Button>
        </div>
      </div>
      <div className="media_wrapper2">
        <div className="sub-heading">Audio Setting</div>
        <div className="dropdown-wrapper">
          <div className="media-modify">
            <div className="media-custom">
              <span className="custom-title">Number of times to play</span>
              <FormGroup className="switch-size">
                <Stack direction="row" spacing={1} alignItems="center">
                  <AntSwitch
                    checked={toggles.playCount}
                    onChange={() => handleToggle("playCount")}
                    inputProps={{ "aria-label": "Play Count Toggle" }}
                  />
                </Stack>
              </FormGroup>
            </div>
            <div className="custom-text">
              <TextField
                id="playCount"
                name="playCount"
                variant="outlined"
                placeholder="Type here"
                value={inputValues.playCount}
                onChange={handleInputChange}
                disabled={!toggles.playCount}
                inputProps={{
                  maxLength: 10,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    transform: "inherit",
                  },
                }}
                InputProps={{
                  style: { borderRadius: "4px" },
                }}
              />
            </div>
          </div>

          <div className="media-modify">
            <div className="media-custom">
              <span className="custom-title">Time duration to play</span>
              <FormGroup>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AntSwitch
                    checked={toggles.timeDuration}
                    onChange={() => handleToggle("timeDuration")}
                    inputProps={{ "aria-label": "Time Duration Toggle" }}
                  />
                </Stack>
              </FormGroup>
            </div>
            <div className="custom-text">
              <TextField
                id="timeDuration"
                name="timeDuration"
                variant="outlined"
                placeholder="Type here"
                value={inputValues.timeDuration ? `${inputValues.timeDuration}s` : ""}
                onChange={handleInputChange}
                disabled={!toggles.timeDuration}
                inputProps={{
                  maxLength: 100,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    transform: "inherit",
                  },
                }}
                InputProps={{
                  style: { borderRadius: "4px" },
                }}
              />
            </div>
          </div>
        </div>

        <div className="error-btn-container">
          <div className="error-container">
            {errors.playCount && (
              <div className="customerror">{errors.playCount}</div>
            )}
            {errors.timeDuration && (
              <div className="customerror">{errors.timeDuration}</div>
            )}
          </div>
          <div className="btn-wrapper">
            <Button
              id="audioSave"
              variant="contained"
              onClick={handleSubmit}
              disabled={!isAudioSaveEnabled}
              className="save"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <CustomizeAudio />
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          pointerEvents: "none",
          ...(enableSnackbar && { paddingTop: "45px" }),
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Setting Saved Successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MediaSettings;
