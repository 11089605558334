import React, { useState, useEffect } from 'react';
import SecuritySettings from './SecuritySettings/SecuritySettings'
import EmergencySettings from './EmergencySettings/EmergencySettings'
import MediaSettings from './MediaSettings/MediaSettings'
import DrillModeSetting from './DrillModeSetting/DrillModeSetting'
import './GlobalSetting.scss'

const GlobalSetting = () => {
    const [activeScreen, setActiveScreen] = useState('securitySetting')
    return (
        <>

            <div className="profileWrapper">
                <div className="main_heading">
                    <h1>Global Settings</h1>
                </div>
                <div className="menu_bar">
                    <ul style={{ width: "auto" }}>
                        <li>
                            <button
                                className={activeScreen === 'securitySetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('securitySetting')}
                            >
                                Security
                             </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'mediaSetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('mediaSetting')}
                            >
                                Media
                            </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'emergencySetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('emergencySetting')}
                            >
                                Emergency
                            </button>
                        </li>
                        <li>
                            <button
                                className={activeScreen === 'drillModeSetting' ? 'active' : ''}
                                onClick={() => setActiveScreen('drillModeSetting')}
                            >
                                Drill Mode
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="userPassdDetail_wrapper">
                    {activeScreen == "securitySetting" ? <SecuritySettings /> : activeScreen == "emergencySetting" ? <EmergencySettings /> : activeScreen == "drillModeSetting" ? <DrillModeSetting /> : <MediaSettings />}
                </div>
            </div>

        </>
    );
};

export default GlobalSetting;
