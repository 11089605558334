import axios from "axios";
// Dashboard Data API
export const setRowsHandler = () => async (dispatch) => {
  let token = JSON.parse(localStorage.getItem("token"));
  dispatch({ type: "ROWS_DATA_REQUEST" });
  try{
    const url = `${process.env.REACT_APP_DEVICE_API_URL}/floor/floorList`
    const response = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
    });
    if (response.status === 200) {
      dispatch({
        type: 'ROWS_DATA_SUCCESS',
        payload: response.data,
      })
    }
  } catch (error) {
    dispatch({
      type: 'ROWS_DATA_FAILURE',
      payload: error.message,
    })
  }
}
// getAllListGlobalAppSetting Data API
export const fetchSettings = () => async (dispatch, getState) => {
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    const url = `${process.env.REACT_APP_API_URL}/auth/getAllListGlobalAppSetting`;
    const response = await axios({
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const fetchedSettings = {};
      response.data.allGlobalSettings.forEach((item) => {
        fetchedSettings[item.code] = item.value;
      });
      const enableSnackbar = getState().snackbar.enableSnackbar;
      if (fetchedSettings["isStrobeSpeakersEnabled"] == 0 && enableSnackbar) {
        dispatch({
          type: "SET_DRILL_SPK_AND_STROBE",
          payload: true,
        });
      }

      dispatch({
        type: "GLOBALSETTING_DATA_SUCCESS",
        payload: fetchedSettings,
      });
    }
  } catch (error) {
    dispatch({
      type: "GLOBALSETTING_DATA_FAILURE",
      payload: error.message,
    });
  }
};


export const updateFloorData = (value) => ({
    type: "FloorData",
    payload: value,
});
  
export const activeFloorPlan = (value) => ({
    type: "activeFloorPlan",
    payload: value,
});
  
export const activeFloorPrintView = (value) => ({
    type: "SET_ACTIVE_FLOOR_PRINT_VIEW",
    payload: value,
}
);

export const floorMapRef = (value) => ({
    type: "SET_ACTIVE_FLOOR_REF",
    payload: value,
}
);

