import React, { useEffect, useState, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import closeIcon from '../../../assets/images/closeicon.svg'
import recordingIcon from '../../../assets/images/recordingIcon.svg';
import recordingIconActive from '../../../assets/images/recordingIconActive.svg';
import playIcon from '../../../assets/images/playIcon.svg'
import checkImg from "../../../assets/images/check_circle.svg";
import pauseIcon from '../../../assets/images/pauseIcon.svg'
import { Validation } from '../../../Validation/Validation';
import {baseUrl} from '../../../config/apiConfig'
import axios from "axios";
import TextField from "@mui/material/TextField";
import { Button, Checkbox, Box, Modal, CircularProgress } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  paddingLeft: 4,
  paddingRight: 4,
};
const AddAudio = ({ open, setOpen,setReloadAudioList }) => {
  const [formData, setFormData] = useState({ title: "" });
  const [showError, setShowError] = useState(false);
  const [formError, setFormError] = useState("");
  const [audioBlobFile, setAudioBlobFile] = useState(null);
  const [loop, setLoop] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [audioErrorMessage, setAudioErrorMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const waveformRef = useRef(null);
  const rangeInputRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const [regionTimes, setRegionTimes] = useState({ start: 0, end: 0 });
  const wsRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const handleValidation = () => {
    let isValid = true;

    if (Validation.empty(formData.title)) {
      setFormError("Please enter your Title");
      isValid = false;
    }
    setShowError(true);
    return isValid;
  };

  const random = (min, max) => Math.random() * (max - min) + min;
  const randomColor = () =>
    `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;
    const blackRegionColor = () => "rgba(0, 0, 0, 0.1)";
  useEffect(() => {
    if (!audioUrl) return;

    const regions = RegionsPlugin.create();
    const ws = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "rgb(200, 0, 200)",
      progressColor: "rgb(100, 0, 100)",
      url: audioUrl,
      plugins: [regions],
    });

    wsRef.current = ws;

    // Add regions after the audio is decoded
    ws.on("decode", () => {
      regions.addRegion({
        start: 0,
        end: 8,
        content: "Click here to preview (play/pause)",
        color: blackRegionColor(),
        drag: false,
        resize: true,
      });
    });

 
  // Enable drag selection
  regions.enableDragSelection({
    color: blackRegionColor(), // Use the same black region color
  });
    // Handle region updates
    regions.on("region-updated", (region) => {
      console.log("Updated region", region);
      setRegionTimes({ start: region.start, end: region.end });
    });

    // Handle region-in and region-out events
    let activeRegion = null;
    regions.on("region-in", (region) => {
      console.log("region-in", region);
      activeRegion = region;
    });

    regions.on("region-out", (region) => {
      if (activeRegion === region) {
        if (loop) {
          region.play();
        } else {
          activeRegion = null;
          setIsPlaying(false);
        }
      }
    });

    // regions.on("region-clicked", (region, e) => {
    //   e.stopPropagation(); // prevent triggering a click on the waveform
    //   activeRegion = region;
    //   region.play();
    //   region.setOptions({ color: randomColor() });
    // });
   
    // Handle region-clicked event
    let isRegionPlaying = false;
    regions.on("region-clicked", (region, e) => {
      e.stopPropagation(); 
      if (isRegionPlaying) {
        ws.pause();
        setIsPlaying(false);
      } else {
        region.play();
        setIsPlaying(true);
      }
      isRegionPlaying = !isRegionPlaying; // Toggle play state
      activeRegion = region; 
    });

    // Reset active region on waveform interaction
    ws.on("interaction", () => {
      activeRegion = null;
    });

    // Zoom handling
    ws.once("decode", () => {
      if (rangeInputRef.current) {
        rangeInputRef.current.oninput = (e) => {
          const minPxPerSec = Number(e.target.value);
          ws.zoom(minPxPerSec);
        };
      }
    });

    // Event listener for when the audio finishes playing
    ws.on("finish", () => {
      console.log("Audio finished playing");
      setIsPlaying(false); // Set the play state to false when the audio finishes
      activeRegion = null;
      ws.seekTo(0); // Reset the position to the start of the audio
    });

    return () => {
      ws.destroy(); // Cleanup WaveSurfer instance on component unmount
    };
  }, [audioUrl, loop]);
  async function trimAudioBlob(audioBlob, startTime, endTime) {
    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioContext = new AudioContext();
    const decodedData = await audioContext.decodeAudioData(arrayBuffer);

    const sampleRate = decodedData.sampleRate;
    const startSample = Math.floor(startTime * sampleRate);
    const endSample = Math.floor(endTime * sampleRate);
    const channelCount = decodedData.numberOfChannels;
    const frameCount = endSample - startSample;

    // Create a new buffer for the trimmed audio
    const trimmedBuffer = audioContext.createBuffer(
      channelCount,
      frameCount,
      sampleRate
    );

    // Copy the samples from the original buffer to the trimmed buffer
    for (let channel = 0; channel < channelCount; channel++) {
      const channelData = decodedData.getChannelData(channel);
      trimmedBuffer.copyToChannel(
        channelData.subarray(startSample, endSample),
        channel
      );
    }

    // Now encode the trimmed audio buffer to a WAV file
    const wavBlob = await audioBufferToWavBlob(trimmedBuffer);
    return wavBlob;
  }


  async function audioBufferToWavBlob(audioBuffer) {
    function writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    }

    const numOfChan = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numOfChan * 2 + 44;
    const buffer = new ArrayBuffer(length);
    const view = new DataView(buffer);

    // RIFF chunk descriptor
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + audioBuffer.length * numOfChan * 2, true);
    writeString(view, 8, "WAVE");
    // FMT sub-chunk
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, audioBuffer.sampleRate, true);
    view.setUint32(28, audioBuffer.sampleRate * numOfChan * 2, true);
    view.setUint16(32, numOfChan * 2, true);
    view.setUint16(34, 16, true);
    // data sub-chunk
    writeString(view, 36, "data");
    view.setUint32(40, audioBuffer.length * numOfChan * 2, true);

    // Write interleaved data
    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        let sample = audioBuffer.getChannelData(channel)[i];
        // clamping
        if (sample > 1) sample = 1;
        else if (sample < -1) sample = -1;
        sample = sample * 32767;
        view.setInt16(offset, sample, true);
        offset += 2;
      }
    }

    return new Blob([view], { type: "audio/wav" });
  }
  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      setAudioUrl(null);
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          audioChunksRef.current = [];
          mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };
          mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/mp3",
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            setAudioBlobFile(audioBlob);
          };
          mediaRecorderRef.current.start();
          setIsRecording(true);
        })
        .catch((err) => console.error("Error accessing microphone:", err));
    } else {
      alert("Audio recording not supported on this browser.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
        // Check if the file is an audio file
        if (!file.type.startsWith('audio/')) {
            alert('Please upload only audio files.');
            return;
        }

        const fileUrl = URL.createObjectURL(file);
        setAudioBlobFile(file);
        setAudioUrl(fileUrl);
    }
};



  // Play or pause audio
  // const togglePlayPause = () => {
  //   if (wsRef.current) {
  //     if (isPlaying) {
  //       wsRef.current.pause(); // Pause the audio
  //     } else {
  //       if (wsRef.current.getDuration() === wsRef.current.getCurrentTime()) {
  //         // If audio is finished, reset and play again
  //         wsRef.current.seekTo(0); // Reset position
  //       }
  //       wsRef.current.play(); // Play the audio
  //     }
  //     setIsPlaying(!isPlaying); // Toggle the play/pause state
  //   }
  // };

  const handleClose = () => {
    setLoop(true);
    setIsRecording(false);
    setAudioUrl();
    setIsPlaying(false);
    setOpen(false);
    setFormData({ title: "" });
    setSuccessMessage(false)
    setReloadAudioList(false)
    setAudioErrorMessage("")
    waveformRef.current = null;
    rangeInputRef.current = null;
    mediaRecorderRef.current = null;
    audioChunksRef.current = [];
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    if (!audioBlobFile) {
        alert("Please record an audio first.");
        return;
    }
    
    // Check if the audio file size exceeds 2MB (2 * 1024 * 1024 bytes)
    // const maxSizeInMB = 2;
    // const fileSize = audioBlobFile.size / (1024 * 1024); // in MB
    
    // if (fileSize > maxSizeInMB) {
    //     setAudioErrorMessage(`The audio file exceeds the ${maxSizeInMB}MB limit. Please record a shorter audio.`);
    //     return;
    // }

  if (!handleValidation()) return;

  const { start, end } = regionTimes;

  // Initialize trimmedAudioBlob with default value
  let trimmedAudioBlob = audioBlobFile;

  // If the start and end times are valid, trim the audio
  if (end > start) {
      trimmedAudioBlob = await trimAudioBlob(audioBlobFile, start, end);

      // Check if the trimmed audio size exceeds 2MB
      // const trimmedFileSize = trimmedAudioBlob.size / (1024 * 1024); // in MB
      // if (trimmedFileSize > maxSizeInMB) {
      //     setAudioErrorMessage(`The trimmed audio file exceeds the ${maxSizeInMB}MB limit. Please adjust the trim region.`);
      //     return;
      // }

      // Create a temporary link to download the trimmed audio
      // const url = URL.createObjectURL(trimmedAudioBlob);
      // const a = document.createElement("a");
      // a.style.display = "none";
      // a.href = url;
      // a.download = "trimmedAudio.wav"; // File name
      // document.body.appendChild(a);
      // a.click(); // Trigger download
      // document.body.removeChild(a); // Clean up
      // URL.revokeObjectURL(url);
  }

  try {
      const token = JSON.parse(localStorage.getItem("token"));

        // Form the request URL
        const url = new URL(`${process.env.REACT_APP_API_URL}/uploadAlarmMedia`);

      // Prepare formData for the audio upload
      const formDataToSend = new FormData();
      formDataToSend.append("filepath", trimmedAudioBlob); // Attach the trimmed audio file
      formDataToSend.append("title", formData.title); // Add title
      formDataToSend.append("fileMediaType", "audio"); // Specify media type
      formDataToSend.append("isDefault", false); // Add default flag

      // Send the request
      const response = await axios.post(url.toString(), formDataToSend, {
          headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
          },
      });

        if (response.status === 200) {
            setSuccessMessage(true);
            setReloadAudioList(true);
            setIsLoading(false)
            
        }
    } catch (error) {
      setIsLoading(false)
        setAudioErrorMessage(error.response.data.message);
        console.error("Error making request:", error);
    }
};




  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="addFlorModal"
    >
      <Box sx={style}>
        <div className="Modal_header">
          <h1>Create New Audio</h1>
          <button onClick={() => handleClose()} className="close_button">
            <img src={closeIcon} alt="img" />
          </button>
        </div>
        <div className="Modal_middle  audioMiddle login-form">
        {!successMessage ? (  
          <> 
            {audioUrl ? (
            <div className="audioTitle">
              <TextField
                id="title"
                name="title"
                label="Title"
                variant="outlined"
                onChange={handleInputChange}
                // onKeyPress={handleKeyPress}
                value={formData.title}
                error={!!formError && showError}
                helperText={formError}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    transform: "inherit",
                  },
                }}
                InputProps={{
                  style: { borderRadius: "4px" },
                }}
              />
            </div>
          ) : (
            ""
          )}
          {/* <div className="playbtn_box">
            {audioUrl ?
              <button onClick={() => togglePlayPause()}>
                <img src={isPlaying ? pauseIcon : playIcon}  alt={isPlaying ? "pauseIcon" : "playIcon"}/>
              </button>
              : ""}
          </div> */}
          <div id="waveform" ref={waveformRef}></div>
        {isLoading  &&   <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>} 
         <p className="audioErrorMessage">{audioErrorMessage ? audioErrorMessage : ""}</p>
          {audioUrl ? (
            <div className="loopRegions">
              {/* <label>
                <input
                  type="checkbox"
                  checked={loop}
                  onChange={(e) => setLoop(e.target.checked)}
                />
                Loop regions
                </label> */}

              <label>
                Zoom:{" "}
                <input
                  ref={rangeInputRef}
                  type="range"
                  min="10"
                  max="1000"
                  defaultValue="10"
                />
              </label>
            </div>
          ) : (
            ""
          )}

          <div className="">
            {/* <input type="file" accept="audio/*" onChange={handleFileUpload} /> */}
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload Audio
            </label>
            <input
              id="file-upload"
              type="file"
              accept="audio/*"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </div>
          <div className="recording-file-upload">
            <button onClick={isRecording ? stopRecording : startRecording}>
              {isRecording ? "Stop Recording" : "Start Recording"}
            </button>
            <img src={isRecording ? recordingIconActive : recordingIcon} />
            {isRecording ? (
              <span className="recordingtext"> Recording... </span>
            ) : (
              ""
            )}
          </div>
           </> ) :<div className="emailsent-wrapper manageUser_details">
                            <img src={checkImg} alt="img" />
                            <h2>Success</h2>
                            <p>Audio has created successfully.</p>
                        </div>}
        </div>
        <div className="Modal_footer adddeviceFooter">
          {/* Buttons */}
          {!successMessage &&  <Box sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              className="cancelBtn"
              onClick={(e) => handleClose()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="saveBtn"
              onClick={(e) => handleSubmit(e)}
            >
              Save
            </Button>
          </Box>}
        </div>
      </Box>
    </Modal>
  );
};

export default AddAudio;
