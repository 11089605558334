const initialState = {
    data: null,
    isLoading: false,
    error: '',
    isDeleted: false,
  };
  const UserReducer = (state = initialState, action) => {
    switch (action.type) {
      case "LIST_USER_LOADING":
        return {
          ...state,
          isLoading: true,
          data: null,
          error: '',
        };
      case "LIST_USER_SUCCESS":
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          error: '',
        };
      case "LIST_USER_ERROR":
        return {
          ...state,
          isLoading: false,
          data: null,
          error: action.payload,
          isDeleted: false,
        };
      case "DELETE_USER_LOADING":
        return {
          ...state,
          isLoading: true,
          data: null,
          error: '',
          isDeleted: false,
        };
      case "DELETE_USER_ERROR":
        return {
          ...state,
          isLoading: false,
          data: null,
          error: action.payload,
          isDeleted: false,
        };
      case "DELETE_USER_SUCCESS":
        return {
          ...state,
          isLoading: false,
          isDeleted: true,
        };
      case "DELETE_USER_RESET":
        return {
          ...state,
          isLoading: false,
          isDeleted: false,
        }
      default:
        return state;
    }
  };
  
  export default UserReducer;
  