const initialState = {
  floorData: [],
  rows: [],
  activeFloorData: 1,
  activeFloorPRintData: false,
  activeFloorRef: null,
  isDrillSpkAndStrobe: false,
  settings: {},
};
const FloormapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ROWS_DATA_SUCCESS":
      return {
        ...state,
        rows: action.payload,
      };
    case "FloorData":
      return {
        ...state,
        floorData: action.payload,
      };
    case "activeFloorPlan":
      return {
        ...state,
        activeFloorData: action.payload,
      };
    case "SET_ACTIVE_FLOOR_PRINT_VIEW":
      return {
        ...state,
        activeFloorPRintData: action.payload,
      };
    case "SET_ACTIVE_FLOOR_REF":
      return {
        ...state,
        activeFloorRef: action.payload,
      };
    case 'GLOBALSETTING_DATA_SUCCESS':
      return { 
          ...state, 
          isLoading: false, settings: action.payload };
    case 'GLOBALSETTING_DATA_FAILURE':
      return { 
        ...state, 
        isLoading: false, 
        error: action.payload 
      };
    case 'SET_DRILL_SPK_AND_STROBE':
      return { 
        ...state, 
        isDrillSpkAndStrobe: action.payload 
      };
    default:
      return state;
  }
};

export default FloormapReducer;
